<template>
  <v-dialog v-model="option.print" scrollable persistent :max-width="option.width">
    <v-card>
      <v-card-title class="headline" style="justify-content: space-between">
        <span>打印{{ option.title }}</span>
        <v-btn @click="closeDialog" class="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- 需要传递option -->
      <v-divider></v-divider>

      <v-card-text>
        <div class="pa-8">
          <div id="print-area">
            <slot name="content"></slot>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" color="primary" @click="print"> 打印 </v-btn>
        <v-btn class="ma-2" color="primary" @click="closeDialog"> 取消 </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MyPrint',
  props: {
    option: {
      print: {
        type: Boolean,
      },
      value: {
        type: Boolean,
      },
      width: {
        type: Number,
      },
      title: {
        type: String,
      },
      PrintDialog: {
        type: Function,
      },
      changePrint: {
        type: Function,
      },
    },
  },

  methods: {
    print() {
      printJS({
        printable: 'print-area',
        type: 'html',
        honorMarginPadding: false,
        targetStyles: ['*'],
        font_size: null,
        font: null,
      })
    },
    closeDialog() {
      this.option.print = false
    },
  },
}
</script>
<style lang="scss" scoped>
#print-area {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
