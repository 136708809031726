<template>
  <v-menu ref="menu" v-model="dateMenu" offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false" :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field dense :value="date | publicDate" readonly v-bind="attrs" v-on="on" :disabled="disabled"></v-text-field>
    </template>
    <v-date-picker no-title scrollable :day-format="format" v-model="date" @input="$emit('input', date), (dateMenu = false)" @change="$emit('change', date)">
      <v-spacer v-if="clearable"></v-spacer>
      <v-btn color="primary" mall text @click=";(date = ''), $emit('input', date), $emit('change', date), (dateMenu = false)" v-if="clearable"> 清除 </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
export default {
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateMenu: false,
      date: this.value,
    }
  },
  methods: {
    format(day) {
      return new Date(day).getDate()
    },
  },
  watch: {
    value(val) {
      this.date = val
    },
  },
}
</script>
