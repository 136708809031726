import { RectNode, RectNodeModel } from "@logicflow/core";

class ApprovalNodeView extends RectNode {
}
class ApprovalNodeModel extends RectNodeModel {
  initNodeData(data) {
    data.text = {
      value: data.text.value || data.text || '',
      x: data.x,
      y: data.y,
      draggable: false,
      editable: false
    }
    super.initNodeData(data)
    this.width = 70
    this.height = 70
  }
}

export default function registerApproval(lf) {
  lf.register({
    type: "approval",
    view: ApprovalNodeView,
    model: ApprovalNodeModel
  })
}
