<template>
  <v-dialog v-model="value" scrollable persistent :max-width="$attrs.width">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div
          class="d-flex align-item-center  justify-content-between"
          :class="$attrs.title == '合同基本信息' ? 'my_alert_title' : ''"
        >
          <span class="text-h5">{{ $attrs.title }}</span>
        </div>
        <v-btn class="close" @click="shut">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        id="myalert-text"
        v-if="$attrs.textFlag ? false : true"
        :style="{ height: $attrs.textHeight }"
      >
        <div ref="contentdate">
          <slot></slot>
        </div>
      </v-card-text>
      <slot v-else></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "myAlert",
  inheritAttrs: false,
  props: ["value", "readonly"],
  data() {
    return {};
  },

  beforeMount() {
    this.$nextTick(() => {
      if (this.$refs.contentdate) {
        if (this.$attrs.width <= 1200) {
          this.$refs.contentdate.style.padding = "0 12px";
        } else {
          this.$refs.contentdate.style.padding = "0 24px";
        }
      }
    });
  },

  methods: {
    shut() {
      if (this.$listeners.off) {
        this.$emit("off");
      } else {
        this.$emit("update:value", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .v-dialog {
//   max-height: 800px !important;
// }
// .v-dialog__content{
//     height: initial !important;
// }
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: none;
}
.titleIcon {
  width: 16px;
  height: 16px;
}
.my_alert_title {
  width: auto;
}
</style>
