

import request from "../utility/http"
/**
 * @description 
 * @returns mock生成的随机数
 */
export function getgoods (data) {
    return request.get('/goods/goodAll', data)
}
/**
 * @description 用户登录页面使用
 * @param {*} name 传入的用户名
 * @param {*} pass 传入的密码
 * @returns 用户的jsonWebToken
 */

export function login (data) {
    return request.postForm("/api/Login/GetJwtToken", data)
}

/**
 * @description 在用户的token过期之后发起的请求 用来重新请求一个token
 * @param {*} accessToken 请求后台数据要携带的Token
 * @param {*} refreshToken 重新请求Token要用的Token
 * @returns 用户新的accessToken和refreshToken
 */
export function Refresh (data) {
    return request.postForm("/api/Login/RefreshJwtToken", data)
}


/**
 * 
 * @returns 系统维护角色管理的角色列表数据 
 */
export function getUserList1 () {
    return request.get('/aaa/list1')
}

/**
 * 
 * @returns 系统维护角色管理的角色用户数据
 */
export function getUserList2 () {
    return request.get('/bbb/list1')
}