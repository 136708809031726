<template>
  <v-row>
    <v-col cols="5" class="pr-3">
      <v-autocomplete
        dense
        :menu-props="{
          bottom: true,
          offsetY: true,
        }"
        :rules="rules"
        item-text="codeName"
        item-value="codeId"
        :items="list.filter((item) => item.parentId === null)"
        v-model="typeBigId"
        @change="changeTypeBig"
      ></v-autocomplete>
    </v-col>
    <v-col cols="7">
      <v-autocomplete
        dense
        :menu-props="{
          bottom: true,
          offsetY: true,
        }"
        :rules="rules"
        item-text="codeName"
        item-value="codeId"
        :items="list.filter((item) => item.parentId === typeBigId && typeBigId)"
        v-model="typeId"
        @change="changeType"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SelectSupplierType', //账户供应商类别
  props: {
    value: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      typeBigId: null,
      typeId: this.value,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.typeId = this.value
      if (this.value) this.typeBigId = this.list.find((item) => item.codeId == this.value).parentId
    },
    changeTypeBig() {
      this.typeId = null
      this.$emit('input', '')
    },
    changeType() {
      this.$emit('input', this.typeId)
      this.$emit('change', this.typeId)
    },
  },
  watch: {
    value() {
      this.init()
    },
    list() {
      if (this.value) this.typeBigId = this.list.find((item) => item.codeId == this.value).parentId
    },
  },
}
</script>

<style lang="scss" scoped></style>
