<template>
  <div class="upFile">
    <div>
      <div v-if="FileDataList.length && !readonly" style="width: 100%; height: 11px"></div>
      <div>
        <span v-for="(item, index) in FileDataList" :key="index" :class="!readonly ? 'd-block' : 'pr-2'">
          <span @click="getFile(item.guid)" class="hover" :class="isPrint ? '' : 'line_44'">
            {{ item.fileName }}
          </span>

          <el-popconfirm v-if="!readonly" title="确定删除这个附件么？" @confirm="clearfile(item, index)" :hide-icon="true" class="cursor" popper-class="area_popper">
            <v-icon small slot="reference" class="clear_hover">mdi-close</v-icon>
          </el-popconfirm>
        </span>
      </div>
      <v-file-input id="fileInput" v-if="!readonly" style="padding: 0" dense v-model="fi" @change="upFile" :clearable="false"> </v-file-input>
    </div>
  </div>
</template>
<script>
// import '' from  ''
import * as fileApi from '@/api/SysAttachmentUpload'
import global from '../../utility/globals.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'CctFileInput',
  components: {},
  props: {
    GuId: {
      type: String,
      default: '',
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isPrint: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fi: {},
      FileDataList: [],
    }
  },

  async created() {
    this.getData()
  },
  methods: {
    async getData() {
      if (this.GuId) {
        let res = await fileApi.GetByRelationGuid(this.GuId)
        this.FileDataList = res
      }
    },
    async upFile(file) {
      if (!file || !file.name) {
        return
      }
      let res = undefined
      const fileStorageType = await this.$store.dispatch('app/getFileStorageType')
      //fileStorageType：1就是本地磁盘存储,2是阿里云OSS服务端签名直传
      if (fileStorageType == 2) {
        const ossPolicyToken = await this.$store.dispatch('app/getOssPolicyToken')
        const uuid = uuidv4()
        const now = new Date()
        const fullPath = `${ossPolicyToken.dir}${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}/${uuid}/${file.name}`
        let data = {
          key: fullPath,
          policy: ossPolicyToken.policy,
          OSSAccessKeyId: ossPolicyToken.accessid,
          success_action_status: 200,
          callback: ossPolicyToken.callback,
          signature: ossPolicyToken.signature,
          'x:guid': uuid,
          'x:relationguid': this.GuId,
          'x:userid': this.$cookies.get('id') || '0',
          file: file,
        }
        res = await fileApi.UploadToOSS(ossPolicyToken.host, data)
      } else {
        res = await fileApi.Upload(this.GuId, file)
      }
      if (res) {
        this.$message({ type: 'success', message: '上传成功' })
        this.fi = {}
        document.getElementById('fileInput').value = null
        this.FileDataList.push(res)
      } else {
        this.$message({ type: 'error', message: '上传失败' })
      }
    },
    async clearfile(item, index) {
      let res = await fileApi.Delete(item.guid)
      if (res) {
        this.$message({ type: 'success', message: '删除成功' })
        this.FileDataList.splice(index, 1)
      } else {
        this.$message({ type: 'error', message: '删除失败' })
      }
    },
    async getFile(guid) {
      window.open(global.apiBaseURL + `/api/SysAttachment/Download/${guid}`)
    },
  },
  watch: {
    GuId(newVal, oldVal) {
      this.getData()
    },
  },
}
</script>
<style lang="scss" scoped></style>
