<template>
  <v-row>
    <div class="flex justify-content-between" v-if="only == 2">
      <div class="tab2">
        <div class="xuanzetitle line_44">已选</div>
        <ul class="yixuan">
          <li v-for="(item, index) in yixuansun" :key="index" @click="jiaohuan('yixuan', index)">
            {{ item.userName }}
          </li>
        </ul>
      </div>
      <div class="img">
        <img :src="require('@/assets/img/switch.png')" alt="" />
      </div>
      <div class="tab2">
        <div class="xuanzetitle line_44">待选</div>
        <ul class="yixuan">
          <li v-for="(item, index) in daixuansun" :key="index" @click="jiaohuan('daixuan', index)">
            {{ item.userName }}
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-content-between" v-else>
      <div class="tab2">
        <div class="xuanzetitle">待选</div>
        <ul class="yixuan">
          <li v-for="(item, index) in searchDai" :key="index" @click="jiaohuan('daixuan', index)">
            {{ item.userName }}
          </li>
        </ul>
        <!-- <div class="d-flex">
          <div class="line_44">搜索</div>
          <v-text-field v-model="search"></v-text-field>
        </div> -->
        <v-row>
          <v-col cols="3">
            <div class="text-right line_44" style="line-height: 48px !important">搜索</div>
          </v-col>
          <v-col cols="9">
            <v-text-field v-model="search"></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div class="img">
        <img :src="require('@/assets/img/switch.png')" alt="" />
      </div>
      <div class="tab2">
        <div class="xuanzetitle">已选</div>
        <ul class="yixuan mr-2">
          <li v-for="(item, index) in yixuansun" :key="index" @click="jiaohuan('yixuan', index)">
            {{ item.userName }}
          </li>
        </ul>
      </div>
    </div>
  </v-row>
</template>

<script>
export default {
  name: 'FiltList',
  props: ['yixuan', 'daixuan', 'only'],
  data() {
    return {
      yixuansun: [],
      daixuansun: [],
      search: '',
      searchDai: [],
    }
  },
  created() {
    this.yixuansun = this.yixuan.concat()
    this.searchDai = this.daixuansun = this.daixuan.concat()
  },
  mounted() {},
  watch: {
    yixuan() {
      this.yixuansun = this.yixuan.concat()
    },
    daixuan() {
      this.searchDai = this.daixuansun = this.daixuan.concat()
    },
    search() {
      this.searchDai = this.daixuansun.filter((item) => {
        if (this.search == '') return item
        return item.userName.includes(this.search) || item.loginName.includes(this.search)
      })
    },
  },
  methods: {
    jiaohuan(text, index) {
      if (text == 'yixuan') {
        this.daixuansun.push(...this.yixuansun.splice(index, 1))
      } else if (this.only != 1) {
        this.yixuansun.push(...this.searchDai.splice(index, 1))
      } else if (this.yixuansun.length != '') {
        this.yixuansun = [...this.searchDai.splice(index, 1, this.yixuansun[0])]
      } else {
        this.yixuansun = [...this.searchDai.splice(index, 1)]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.yixuan {
  height: 404px;
  border: 1px solid #eee;
  overflow: auto;
}
.v-application ul,
.v-application ol {
  padding-left: 0;
}
.xuanzetitle {
  text-align: center;
}
li {
  // height: 30px;
  // line-height: 30px;
  padding-left: 14px;
  border-bottom: 1px solid #eee;
}
.tab2 {
  width: 45%;
  color: #666 !important;
  font-size: 0.875rem;
}
.img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  height: 404px;
  width: 10%;
}
.line_70 {
  margin-top: 28px;
  margin-right: 10px;
}
</style>
