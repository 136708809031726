<template>
  <v-card class="h100">
    <slot name="title"></slot>
    <el-tree
      ref="popularTree"
      :data="data"
      :default-expanded-keys="defaultExpandedKeys"
      :default-expand-all="defaultexpandall"
      node-key="id"
      @node-drag-start="handleDragStart"
      @node-drag-enter="handleDragEnter"
      @node-drag-leave="handleDragLeave"
      @node-drag-over="handleDragOver"
      @node-drag-end="handleDragEnd"
      @node-drop="handleDrop"
      :draggable="flag ? true : false"
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
      @node-click="nodeClick"
      :highlight-current="true"
      :props="{
        label: Name,
      }"
    >
    </el-tree>
  </v-card>
</template>

<script>
export default {
  name: "Tree",
  props: {
    flag: { type: Boolean },
    propgetList: { type: Function },
    Name: { type: String },
    Home: { type: Boolean },
    defaultexpandall: {
      type: Boolean,
      default: true,
    },
    defaultExpandedKeys: {
      type: Array,
    },
  },
  data () {
    return {
      data: [],
      dataCopy: []
    };
  },

  async created () {
    await this.getList();
    this.nodeClick(this.data[0]);
  },
  mounted () { },

  methods: {
    async getList (id) {
      let data = await this.propgetList();
      this.data = generateTree(data);
      if (this.Home) {
        this.data = [{ [this.Name]: "Home", children: this.data, id: -1 }];
      }
      if (id) {
        this.$nextTick(function () {
          this.$refs.popularTree?.setCurrentKey(id);
        });
      } else {
        this.$nextTick(function () {
          this.$refs.popularTree?.setCurrentKey(this.data[0].id);
        });
      }
      function generateTree (nodeList) {
        const nodeMap = nodeList.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
        const res = [];
        nodeList.forEach((node) => {
          if (!node.parentId) {
            res.push(node);
            return;
          }
          const parentNode = nodeMap[node.parentId];
          parentNode.children = [...(parentNode.children || []), node];
        });
        return res;
      }


    },
    handleDragStart (node, ev) {

      this.$emit('handleDragStart', this.data)
      // console.log("drag start", node);
      // console.log("this.data", this.data);
    },
    handleDragEnter (draggingNode, dropNode, ev) {
      // console.log("tree drag enter: ", dropNode.label);
      // console.log("this.data", this.data);
    },
    handleDragLeave (draggingNode, dropNode, ev) {
      // console.log("tree drag leave: ", dropNode.label);
      // console.log("this.data", this.data);
    },
    handleDragOver (draggingNode, dropNode, ev) {
      // console.log("tree drag over: ", dropNode.label);
      // console.log("this.data", this.data);
    },
    handleDragEnd (draggingNode, dropNode, dropType, ev) {

      // console.log("tree drag end: ", dropNode && dropNode.label, dropType);

    },
    handleDrop (draggingNode, dropNode, dropType, ev) {
      this.$emit('handleDrop', draggingNode, dropNode,)
      // console.log("tree drop: ", dropNode.label, dropType);
      // console.log("this.data", this.data);
    },
    allowDrop (draggingNode, dropNode, type) {
      return type == "inner";
    },
    allowDrag (draggingNode) {
      return draggingNode.data.parentId ? true : false
    },
    nodeClick (item) {
      this.$emit("transmitData", item);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  font-size: 16px;
  font-weight: 700;
}
::v-deep .el-icon-caret-right {
  font-size: 17px;
}
::v-deep .el-icon-caret-right:before {
  color: #f53f3f;
}
::v-deep .el-tree-node__expand-icon.is-leaf::before {
  visibility: hidden;
}
::v-deep .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
  color: #c0c4cc;
}
::v-deep .el-tree-node__content {
  height: 38px;
}
</style>
