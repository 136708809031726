var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('my-dialog',{attrs:{"option":_vm.option}},[_c('template',{slot:"content"},[_c('div',{staticStyle:{"padding":"12px 24px !important"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"text-right line_44"},[_vm._v("节点名称")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":""},model:{value:(_vm.data.nodeName),callback:function ($$v) {_vm.$set(_vm.data, "nodeName", $$v)},expression:"data.nodeName"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"text-right line_44"},[_vm._v("可选办理人")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"dense":"","rules":_vm.$requiredRules,"item-text":"text","item-value":"value","menu-props":{
                      bottom: true,
                      offsetY: true,
                    },"items":[
                      { value: true, text: '是' },
                      { value: false, text: '否' },
                    ]},model:{value:(_vm.data.isCanSelectUser),callback:function ($$v) {_vm.$set(_vm.data, "isCanSelectUser", $$v)},expression:"data.isCanSelectUser"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"text-right line_44"},[_vm._v("通过模式")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-select',{attrs:{"dense":"","rules":_vm.$requiredRules,"item-text":"codeName","item-value":"codeId","menu-props":{
                      bottom: true,
                      offsetY: true,
                    },"items":_vm.flowNodePassTypeEnum},model:{value:(_vm.data.nodePassType),callback:function ($$v) {_vm.$set(_vm.data, "nodePassType", $$v)},expression:"data.nodePassType"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"text-right line_44"},[_vm._v("节点用户SQL")])]),_c('v-col',{attrs:{"cols":"10"}},[_c('v-textarea',{attrs:{"dense":"","auto-grow":"","rows":"2"},model:{value:(_vm.data.nodeUserSQL),callback:function ($$v) {_vm.$set(_vm.data, "nodeUserSQL", $$v)},expression:"data.nodeUserSQL"}})],1)],1)],1)],1)],1)],1)]),_c('template',{slot:"bottom"},[_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("保存")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }