import { getAreaList, getCategoryList, getCodeList, getMyCart, getMyProjects, getSceneCategoryList, getSupplierList, getSupplierTypeCategoryList, getSupplierTypeList, getWarehouseLocationList } from '../../api/productLibrary'

const state = {
  Option: {
    AreaList: [],
    CategoryList: [],
    CodeList: [],
    SupplierTypeList: [],
    WarehouseLocationList: [],
    SceneCategoryList: [], //场景的标签列表
  },
  MyCart: [],
  MyProjects: [],
  Supplier: [],
}
const getters = {
  objSupplierType: (state) => {
    let result = {}
    state.Option.SupplierTypeList.forEach((item) => {
      result[item.codeId] = item.codeName
    })
    return result
  },
  objWarehouseLocation: (state) => {
    let result = {}
    state.Option.WarehouseLocationList.forEach((item) => {
      result[item.codeId] = item.codeName
    })
    return result
  },
  optionTypeBig: (state) => {
    let result = [{ text: '大类', value: '' }]
    state.Option.SupplierTypeList.filter((item) => item.parentId === null).forEach((item) => {
      result.push({ text: item.codeName, value: item.codeId })
    })
    return result
  },

  optionArea: (state) => {
    let result = [{ text: '空间', value: '' }]
    state.Option.AreaList.forEach((item) => {
      result.push({ text: item, value: item })
    })
    return result
  },
  optionCategory: (state) => {
    let result = [{ text: '标签', value: '' }]
    state.Option.CategoryList.sort().forEach((item) => {
      result.push({ text: item, value: item })
    })
    return result
  },
  optionSceneCategory: (state) => {
    let result = [{ text: '标签', value: '' }]
    state.Option.SceneCategoryList.sort().forEach((item) => {
      result.push({ text: item, value: item })
    })
    return result
  },
  optionSupplier: (state) => {
    let result = [{ text: '供应商', value: '' }]
    state.Supplier.forEach((item) => {
      result.push({ text: item.unitName, value: item.id })
    })
    return result
  },
}
const mutations = {
  setOption(state, data) {
    state.Option = data
  },
  setMyCart(state, data) {
    state.MyCart = data
  },
  setMyProjects(state, data) {
    state.MyProjects = data
  },
  setSupplier(state, data) {
    state.Supplier = data
  },
}
const actions = {
  async getOption({ commit }) {
    let result = {}
    //if (state.Option.WarehouseLocationList.length > 0) return
    result.WarehouseLocationList = await getWarehouseLocationList()
    result.AreaList = await getAreaList()
    result.SupplierTypeList = await getSupplierTypeList()
    result.CategoryList = await getCategoryList()
    result.CodeList = await getCodeList()
    result.SceneCategoryList = await getSceneCategoryList()
    result = { ...result, ...(await getSupplierTypeCategoryList()) }
    commit('setOption', result)
  },
  async getMyCart({ commit }) {
    let result = []
    result = await getMyCart()
    result.forEach((item) => {
      if (!(item.Count > item.QuantityInStock && item.WarehouseLocation === '10')) item.check = true
    })
    commit('setMyCart', result)
  },
  async getMyProjects({ commit }) {
    if (state.MyProjects.length > 0) return
    let result = []
    result = await getMyProjects()
    commit('setMyProjects', result)
  },
  async getSupplier({ commit }) {
    let result = []
    result = await getSupplierList(1, true)
    commit('setSupplier', result)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
