<template>
  <v-row>
    <v-col cols="5" class="pr-3">
      <v-select
        dense
        :menu-props="{
          bottom: true,
          offsetY: true,
        }"
        :rules="rules"
        item-text="codeName"
        item-value="codeId"
        :items="accountList.filter((item) => item.parentId === null)"
        v-model="companyId"
        @change="changeCompany"
      ></v-select>
    </v-col>
    <v-col cols="7">
      <v-select
        dense
        :menu-props="{
          bottom: true,
          offsetY: true,
        }"
        :rules="rules"
        item-text="codeName"
        item-value="codeId"
        :items="accountList.filter((item) => item.parentId === companyId && companyId)"
        v-model="accountId"
        @change="changeAccount"
      ></v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SelectAccount', //账户选择
  props: {
    value: {
      type: String,
      default: '',
    },
    accountList: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      companyId: null,
      accountId: this.value,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.accountId = this.value
      if (this.value) this.companyId = this.accountList.find((item) => item.codeId == this.value).parentId
    },
    changeCompany() {
      this.accountId = null
      this.$emit('input', '')
    },
    changeAccount() {
      this.$emit('input', this.accountId)
    },
  },
  watch: {
    value() {
      this.init()
    },
    accountList() {
      if (this.value) this.companyId = this.accountList.find((item) => item.codeId == this.value).parentId
    },
  },
}
</script>

<style lang="scss" scoped></style>
