<template>
  <div class="">
    <my-dialog :option="option">
      <template slot="content">
        <div style="padding: 12px 24px !important">
          <v-row>
            <v-col cols="12">
              <v-form ref="form" cols="12">
                <v-row>
                  <v-col cols="2">
                    <div class="text-right line_44">是否条件分支</div>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      dense
                      :rules="$requiredRules"
                      v-model="data.isCondition"
                      item-text="codeName"
                      item-value="codeId"
                      :menu-props="{
                        bottom: true,
                        offsetY: true,
                      }"
                      :items="isConditionEnum"
                    ></v-select>
                  </v-col>
                  <v-col cols="6"> </v-col>
                  <v-col cols="2">
                    <div class="text-right line_44">条件分支SQL</div>
                  </v-col>
                  <v-col cols="10">
                    <v-textarea
                      dense
                      v-model="data.linkConditionSQL"
                      auto-grow
                      rows="2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </template>
      <template slot="bottom">
        <v-btn color="primary" style="margin-right: 10px" @click="save"
          >保存</v-btn
        >
      </template>
    </my-dialog>
  </div>
</template>
<script>
import * as api from "@/api/FlowTemp";

export default {
  name: "CctFlowLinkInfo",
  props: ["option"],
  data() {
    return {
      data: {},
      isConditionEnum: [
        { codeId: false, codeName: "否" },
        { codeId: true, codeName: "是" },
      ],
    };
  },
  methods: {
    async get() {
      let res = await api.GetFlowTempLinkByGuid(this.option.id);
      this.data = res.mainData;
    },
    async save() {
      let res = await api.UpdateFlowTempLink(this.data);
      if (res) {
        this.$message({ type: "success", message: "保存成功" });
      }
    },
  },
  async mounted() {
    await this.get();
  },
};
</script>
<style lang="scss" scoped>
</style>
