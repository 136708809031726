<template>
  <div>
    <my-alert :value.sync="mydialog" :title="'合同基本信息'" :width="'1494px'" :textHeight="'2000px'" v-if="mydialog">
      <v-row>
        <filt-head :mainData="mainData" :title="title" :readonly="readonly" :sysCodeGroups="sysCodeGroups" @getList="getList" :mydialog="mydialog" @updateData="updateData"></filt-head>
        <!-- <v-col cols="12">
            <div class="d-flex justify-space-between">
              <div style="color: #e91e63 !important">
                <v-icon
                  style="color: #e91e63 !important"
                  v-text="'colsi-blur-linear'"
                /><span>&nbsp;合同报价明细 仍有 362,000.00 元未作报价。</span>
              </div>
              <v-btn color="primary" dark @click="alertXiangMu('')">
                +报价
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="ContractAmount.Title"
              :items="ContractAmount.List"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.quyu`]="props">
                <td class="hover" @click="alertXiangMu(props.item)">
                  {{ props.item.quyu }}
                </td>
              </template>
              <template v-slot:[`body.append`]>
                <tr>
                  <td class="text-start font-weight-bold">合计</td>
                  <td
                    v-for="(item, index) in [
                      '',
                      362,
                      400.0,
                      0,
                      302.0,
                      '',
                      0,
                      '',
                    ]"
                    :key="index"
                  >
                    {{ item }}
                  </td>
                </tr>
              </template></v-data-table
            >
          </v-col> -->
        <template v-if="mainData?.bmContractin.id !== 0">
          <v-col cols="12">
            <div class="d-flex justify-space-between" style="padding-top: 10px; align-items: center">
              <div style="color: #e91e63 !important"><v-icon style="color: #e91e63 !important" v-text="'colsi-blur-linear'" /><span style="font-weight: 600">项目及付款计划</span></div>
              <v-btn class="primary" @click="addPlan" :disabled="readonly">新增项目</v-btn>
            </div>
          </v-col>
          <!-- 项目及付款计划 -->
          <template v-for="Fplan in plan.List">
            <v-col :id="`maodian${Fplan.id}`" cols="12" :key="Fplan.name" class="plan" style="margin-top: 24px; padding: 20px 0 !important">
              <v-data-table :headers="plan.Title" :items="Fplan.bmContractinPaymentNodes" hide-default-footer class="elevation-1">
                <!-- 调用   <mycom></mycom> -->

                <template v-slot:top>
                  <v-row>
                    <v-col cols="12" class="d-flex">
                      <h4>
                        {{ Fplan.pmName }}
                        {{ Fplan.pmMoney | formatMoney }}元
                      </h4>
                      <template v-if="Fplan.pmMoney > nodesMoneyTotal(Fplan)">
                        <h4>，还有</h4>
                        <h4 style="color: #e91e63">
                          {{ counted(`${Fplan.pmMoney}-${nodesMoneyTotal(Fplan)}`) | formatMoney }}
                        </h4>
                        <h4>元未做计划。</h4>
                      </template>
                      <template v-if="Fplan.pmMoney < nodesMoneyTotal(Fplan)">
                        <h4>，多做</h4>
                        <h4 style="color: #e91e63">
                          {{ counted(`${nodesMoneyTotal(Fplan)}-${Fplan.pmMoney}`) | formatMoney }}
                        </h4>
                        <h4>元计划！</h4>
                      </template>
                      &nbsp;&nbsp;&nbsp;
                      <span style="margin-top: 2px">
                        <v-icon color="primary" dense @click="UpdatePlan(Fplan.id)" title="编辑">mdi-calendar-edit</v-icon>
                      </span>
                      &nbsp;&nbsp;&nbsp;

                      <el-popconfirm title="重新按比例计算未开票和未到款的付款节点金额？" @confirm="ComputeNodeMoney(Fplan.id)" :hide-icon="true" popper-class="area_popper">
                        <v-icon slot="reference" style="margin-top: 2px; cursor: pointer" class="cursor" color="primary" dense title="重新按比例计算节点金额">mdi-calculator</v-icon>
                      </el-popconfirm>
                      &nbsp;&nbsp;&nbsp;
                      <h4>合同面积：{{ Fplan.pmArea }}m²&nbsp;&nbsp;&nbsp;设计面积：{{ Fplan.realDesignArea }}m²</h4>
                      <v-spacer />
                      <v-btn class="primary" style="float: right" v-if="!readonly" @click="addNodes(Fplan.id)">新增节点</v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`body.append`]>
                  <!-- vue实例外创建 -->

                  <td class="text-center font-weight-bold" style="height: 36px; line-height: 36px">合计</td>
                  <td></td>
                  <td class="font-weight-bold text-end">{{ totilPmList(Fplan.bmContractinPaymentNodes, 'percent') }}%</td>
                  <td class="font-weight-bold text-end">
                    {{ totilPmList(Fplan.bmContractinPaymentNodes, 'money') | formatMoney }}
                  </td>
                  <td class="font-weight-bold text-end">
                    {{ totilPmList(Fplan.bmContractinPaymentNodes, 'invoiceMoney') | formatMoney }}
                  </td>
                  <td class="font-weight-bold text-end">
                    {{ totilPmList(Fplan.bmContractinPaymentNodes, 'gatheringMoney') | formatMoney }}
                  </td>
                  <td class="font-weight-bold text-end">
                    {{ (counted(`${totilPmList(Fplan.bmContractinPaymentNodes, 'invoiceMoney')}* 1000-${totilPmList(Fplan.bmContractinPaymentNodes, 'gatheringMoney')}* 1000`) / 1000) | formatMoney }}
                  </td>
                  <td>
                    <v-progress-linear :value="((totilPmList(Fplan.bmContractinPaymentNodes, 'gatheringMoney') / totilPmList(Fplan.bmContractinPaymentNodes, 'money')) * 100).toFixed(2)" color="#e91e63" background-color="#e91e6310" height="25">
                      <template v-slot :default="{ value }">
                        <span :style="{ color: totilPmList(Fplan.bmContractinPaymentNodes, 'money') > 0 && totilPmList(Fplan.bmContractinPaymentNodes, 'gatheringMoney') / totilPmList(Fplan.bmContractinPaymentNodes, 'money') >= 0.5 ? '#fff' : '' }">{{ totilPmList(Fplan.bmContractinPaymentNodes, 'money') == 0 ? '0.00' : ((totilPmList(Fplan.bmContractinPaymentNodes, 'gatheringMoney') / totilPmList(Fplan.bmContractinPaymentNodes, 'money')) * 100).toFixed(2) }}%</span>
                      </template>
                    </v-progress-linear>
                  </td>
                </template>
                <template v-slot:[`item.name`]="props">
                  <td class="hover" @click="alertNodes(props.item)">
                    {{ props.item.name }}
                  </td>
                </template>
                <template v-slot:[`item.money`]="props">
                  <td class="text-end">
                    {{ props.item.money | formatMoney }}
                  </td>
                </template>
                <template v-slot:[`item.invoiceMoney`]="props">
                  <td class="hover text-end" :class="{ 'font-red': props.item.invoiceMoney != props.item.money && props.item.invoiceMoney > 0 }" @click="alertinvoiceMoney(props.item)">
                    {{ props.item.invoiceMoney | formatMoney }}
                  </td>
                </template>
                <template v-slot:[`item.percent`]="props">
                  <td class="text-end">{{ props.item.percent }}%</td>
                </template>
                <template v-slot:[`item.gatheringMoney`]="props">
                  <td class="hover text-end" @click="alertgatheringMoney(props.item)">
                    {{ props.item.gatheringMoney | formatMoney }}
                  </td>
                </template>
                <template v-slot:[`item.received`]="props">
                  <td class="text-end">
                    {{ ((props.item.invoiceMoney * 1000 - props.item.gatheringMoney * 1000) / 1000) | formatMoney }}
                  </td>
                </template>
                <template v-slot:[`item.gatheringPercent`]="props">
                  <td>
                    <v-progress-linear :value="props.item.money == 0 ? '0.00' : ((props.item.gatheringMoney / props.item.money) * 100).toFixed(2)" color="#e91e63" background-color="#e91e6310" height="25">
                      <template v-slot :default="{ value }">
                        <span :style="{ color: props.item.money > 0 && props.item.gatheringMoney / props.item.money >= 0.5 ? '#fff' : '' }">{{ props.item.money == 0 ? '0.00' : ((props.item.gatheringMoney / props.item.money) * 100).toFixed(2) }}%</span>
                      </template>
                    </v-progress-linear>
                  </td>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="1" :key="Fplan.name" class="plan">
              <div class="text-right line_44">项目类型</div>
            </v-col>
            <v-col cols="1" :key="Fplan.name" class="noBorderPlan">
              <div class="line_44">
                {{ Fplan.pmClass | ProjectExt(Fplan.id, 'pmClass', sysCodeGroups) }}
              </div>
            </v-col>
            <v-col cols="1" :key="Fplan.name" class="noBorderPlan">
              <div class="text-right line_44">作业形式</div>
            </v-col>
            <v-col cols="1" :key="Fplan.name" class="noBorderPlan">
              <div class="line_44">
                {{ Fplan.how | ProjectExt(Fplan.id, 'how', sysCodeGroups) }}
              </div>
            </v-col>
            <v-col cols="1" :key="Fplan.name" class="noBorderPlan">
              <div class="text-right line_44">业态</div>
            </v-col>
            <v-col cols="2" :key="Fplan.name" class="noBorderPlan">
              <div class="line_44">{{ Fplan.bigType | ProjectExt(Fplan.id, 'bigType', sysCodeGroups) }}/{{ Fplan.bigType | ProjectExt(Fplan.id, 'type', sysCodeGroups) }}</div>
            </v-col>
            <v-col cols="1" :key="Fplan.name" class="noBorderPlan">
              <div class="text-right line_44">主创团队</div>
            </v-col>
            <v-col cols="1" :key="Fplan.name" class="noBorderPlan">
              <div class="line_44">
                {{ Fplan.pmMainDept | ProjectExt(Fplan.id, 'pmMainDept', sysCodeGroups) }}
              </div>
            </v-col>
            <v-col cols="1" :key="Fplan.name" class="noBorderPlan">
              <div class="text-right line_44">项目负责人</div>
            </v-col>
            <v-col cols="2" :key="Fplan.name" class="noBorderPlan">
              <div class="line_44">
                {{ Fplan.pmManager | ProjectExt(Fplan.id, 'pmManager', sysCodeGroups) }}
              </div>
            </v-col>
          </template>

          <v-col cols="12">
            <div class="d-flex justify-space-between" style="height: 60px; align-items: center">
              <div style="color: #e91e63 !important"><v-icon style="color: #e91e63 !important" v-text="'colsi-blur-linear'" /><span style="font-weight: 600">合同变更或补充协议</span></div>
              <v-btn class="primary" @click="addAgreement" v-if="!readonly">新增协议</v-btn>
            </div>
          </v-col>
          <v-col cols="12">
            <v-data-table :headers="xinzengTitle" :items="resMainData.bmContractin.bmContractinChanges" hide-default-footer class="elevation-1">
              <template v-slot:[`item.code`]="props">
                <div class="hover" @click="setAgreement(props.item.id)">
                  {{ props.item.code }}
                </div>
              </template>
              <template v-slot:[`item.signDate`]="props">
                {{ props.item.signDate | publicDate }}
              </template>
              <template v-slot:[`item.money`]="props">
                <td class="text-end">
                  {{ props.item.money | formatMoney }}
                </td>
              </template>
              <template v-slot:[`body.append`]>
                <tr>
                  <td class="text-start font-weight-bold">合计</td>
                  <td></td>
                  <td></td>
                  <td class="text-end">
                    {{ totilPmList(resMainData.bmContractin.bmContractinChanges, 'money') | formatMoney }}
                  </td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" class="pt-8"> </v-col>
        </template>
      </v-row>
      <my-alert :value.sync="newProJect" :width="900" :title="'项目信息'" :textHighe="'2000px'" v-if="newProJect">
        <v-form ref="newProJect" lazy-validation v-model="plan.vaild">
          <v-row>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 项目编号</div>
            </v-col>
            <v-col cols="6">
              <v-text-field dense v-model="plan.data.pmCode" :rules="$requiredRules"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 立项日期</div>
            </v-col>
            <v-col cols="2">
              <DatePicker v-model="plan.data.pmRegisterTime" />
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 项目名称</div>
            </v-col>
            <v-col cols="6">
              <v-text-field dense v-model="plan.data.pmName" :rules="$requiredRules"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 权重</div>
            </v-col>
            <v-col cols="2">
              <v-text-field dense v-model="plan.data.pmWorkload"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 金额(元)</div>
            </v-col>
            <v-col cols="2">
              <v-text-field dense v-model="plan.data.pmMoney" :rules="$requiredRules"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 单价(元/㎡)</div>
            </v-col>
            <v-col cols="2">
              <v-text-field dense v-model="plan.data.unitPrice" :rules="$requiredRules"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 税率(%)</div>
            </v-col>
            <v-col cols="2">
              <v-text-field dense v-model="plan.data.taxRate" :rules="$requiredRules"></v-text-field>
            </v-col>

            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 合同面积(m²)</div>
            </v-col>
            <v-col cols="2">
              <v-text-field dense v-model="plan.data.pmArea" :rules="$requiredRules"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44">设计面积(m²)</div>
            </v-col>
            <v-col cols="2">
              <v-text-field dense disabled v-model="plan.data.realDesignArea"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 面积类型</div>
            </v-col>
            <v-col cols="2">
              <v-select dense v-model="plan.data.pmAreaType" item-text="codeName" item-value="codeId" :menu-props="{ bottom: true, offsetY: true }" :rules="$requiredRules" :items="plansysCodeGroups.find((item) => item.codeType == 'PMAreaType').sysCodes"></v-select>
            </v-col>

            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 业态</div>
            </v-col>
            <v-col cols="3">
              <v-select dense v-model="plan.data.bigType" item-text="codeName" item-value="codeId" :menu-props="{ bottom: true, offsetY: true }" :rules="$requiredRules" :items="plansysCodeGroups.find((item) => item.codeType == 'BigType').sysCodes.filter((item) => item.parentId == null)"></v-select>
            </v-col>
            <v-col cols="3">
              <v-select dense class="ml-2" :rules="$requiredRules" v-model="plan.data.type" item-text="codeName" item-value="codeId" :menu-props="{ bottom: true, offsetY: true }" :items="plansysCodeGroups.find((item) => item.codeType == 'BigType').sysCodes.filter((item) => item.parentId == plan.data.bigType)"></v-select>
            </v-col>
            <!-- <v-col cols="2">
            <div class="text-right  line_44">
              <span style="color: #e02222">*</span> 定额档
            </div>
          </v-col>
          <v-col cols="7">
            <v-select
              dense
              v-model="plan.data.quotaLevel"
              :menu-props="{ bottom: true, offsetY: true }"
              :items="[]"
            ></v-select>
          </v-col> -->
            <v-col cols="2">
              <div class="text-right line_44">项目个数</div>
            </v-col>
            <v-col cols="2">
              <v-text-field dense v-model="plan.data.settledNumber"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 是否需要摄影</div>
            </v-col>
            <v-col cols="6">
              <v-radio-group v-model="plan.data.isNeedPhotography" row dense :rules="$requiredRules">
                <v-radio label="是" :value="true"></v-radio>
                <v-radio label="否" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44">层数</div>
            </v-col>
            <v-col cols="2">
              <v-text-field dense v-model="plan.data.buildingStorey"></v-text-field>
            </v-col>

            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 项目类型</div>
            </v-col>
            <v-col cols="4">
              <v-radio-group v-model="plan.data.pmClass" row dense :rules="$requiredRules">
                <v-radio :label="item.codeName" :value="item.codeId" v-for="item in plansysCodeGroups.find((item) => item.codeType == 'PMClass').sysCodes" :key="item.codeId"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 作业形式</div>
            </v-col>
            <v-col cols="4">
              <v-radio-group v-model="plan.data.how" row dense :rules="$requiredRules">
                <v-radio :label="item.codeName" :value="item.codeId" v-for="item in plansysCodeGroups.find((item) => item.codeType == 'How').sysCodes" :key="item.codeId"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44">项目等级</div>
            </v-col>
            <v-col cols="4">
              <v-radio-group v-model="plan.data.pmLevel" row dense>
                <v-radio :label="item.codeName" :value="item.codeId" v-for="item in plansysCodeGroups.find((item) => item.codeType == 'PMLevel').sysCodes" :key="item.codeId"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 保理费补偿</div>
            </v-col>
            <v-col cols="4">
              <v-radio-group v-model="plan.data.isFactoringFee" row dense :rules="$requiredRules">
                <v-radio label="是" :value="true"></v-radio>
                <v-radio label="否" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 项目状态</div>
            </v-col>
            <v-col cols="10">
              <v-radio-group v-model="plan.data.pmStatus" row dense :rules="$requiredRules">
                <v-radio :label="item.codeName" :value="item.codeId" v-for="item in plansysCodeGroups.find((item) => item.codeType == 'PMStatus').sysCodes" :key="item.codeId"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44">备注</div>
            </v-col>
            <v-col cols="10" style="height: auto">
              <v-textarea dense auto-grow v-model="plan.data.pmRemark" row-height="15" rows="2"> </v-textarea>
            </v-col>
            <v-col cols="12">
              <v-divider style="margin-top: 26px"></v-divider>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44">主创团队</div>
            </v-col>
            <v-col cols="4">
              <v-select dense item-text="deptName" item-value="id" v-model="plan.data.pmMainDeptId" :menu-props="{ bottom: true, offsetY: true }" :items="plansysCodeGroups.find((item) => item.codeType == 'PMMainDeptId').sysCodes"></v-select>
            </v-col>
            <v-col cols="6" v-for="(item, index) in pmProjectRole" :key="item.roleCode">
              <v-row>
                <v-col cols="4">
                  <div class="text-right line_44">
                    {{ item.roleName }}
                  </div>
                </v-col>
                <v-col cols="8" class="d-flex" :key="item.roleName">
                  <v-text-field dense v-model="item.userList" disabled></v-text-field>
                  <div class="line_44 text-right peopleRight">
                    <v-btn tile color="primary" class="people" :disabled="readonly" @click="selectLingDao(index)">
                      <v-icon dense>mdi-account</v-icon>
                    </v-btn>
                    <!-- <v-icon @click="selectLingDao">mdi-account </v-icon> -->
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="12" class="d-flex justify-center mt-5">
            <el-popconfirm title="确定删除吗？" @confirm="deletePlan" :hide-icon="true" class="cursor" popper-class="area_popper">
              <v-btn slot="reference" v-if="!readonly" style="margin-right: 10px" type="success" :disabled="plan.type == '新增' || readonly" depressed outlined color="primary">删除 </v-btn>
            </el-popconfirm>

            <v-btn depressed v-if="!readonly" color="primary" @click="setPlan" :disabled="readonly"> 保存 </v-btn>
          </v-col>
          <v-col cols="12">
            <div style="height: 20px"></div>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </my-alert>
    </my-alert>
    <my-alert :value.sync="flagAgreement" :title="'补充协议'" :width="'900'">
      <v-card-text>
        <v-form ref="flagAgreement" lazy-validation>
          <v-row>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 协议编号</div>
            </v-col>
            <v-col cols="4">
              <v-text-field dense v-model="agreement.code" :rules="$requiredRules"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 协议名称</div>
            </v-col>
            <v-col cols="4">
              <v-text-field dense v-model="agreement.name" :rules="$requiredRules"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 签订日期</div>
            </v-col>
            <v-col cols="4">
              <DatePicker v-model="agreement.signDate" />
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44"><span style="color: #e02222">*</span> 金额</div>
            </v-col>
            <v-col cols="2">
              <v-select
                :rules="$requiredRules"
                dense
                item-text="lable"
                item-value="value"
                v-model="agreement.moneyType"
                :menu-props="{ bottom: true, offsetY: true }"
                :items="[
                  { lable: '增加', value: true },
                  { lable: '减少', value: false },
                ]"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-text-field dense class="ml-2" v-model="agreement.money" :rules="$requiredRules"></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44">保理费补偿</div>
            </v-col>
            <v-col cols="10">
              <v-radio-group v-model="agreement.isFactoringFee" row dense>
                <v-radio label="是" :value="true"></v-radio>
                <v-radio label="否" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="2">
              <div class="text-right line_44">备注</div>
            </v-col>

            <v-col cols="10" style="height: auto">
              <v-textarea dense auto-grow v-model="agreement.content" row-height="15" rows="2"> </v-textarea>
            </v-col>
            <v-col cols="12" class="mt-2" v-if="agreementTitle == '新增'">
              <div style="text-align: center">
                <v-btn depressed color="primary" v-if="!readonly" @click="sumbitAgreement"> 保存 </v-btn>
              </div>
            </v-col>
            <template v-else>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn depressed v-if="!readonly" class="mr-2" color="primary" @click="deleteAgreement" outlined> 删除 </v-btn>
                <v-btn depressed v-if="!readonly" color="primary" @click="sumbitAgreement"> 保存 </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-form>
      </v-card-text>
    </my-alert>
    <my-alert :value.sync="nodes.Flag" v-if="nodes.Flag" :title="'付款节点'" :width="'900'">
      <v-form ref="form" lazy-validation v-model="vaild">
        <v-row>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 排序号</div>
          </v-col>
          <v-col cols="4">
            <v-text-field dense v-model="nodes.Data.sort" :rules="$requiredRules"></v-text-field>
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44">比例</div>
          </v-col>
          <v-col cols="4">
            <v-text-field dense v-model="nodes.Data.percent" @input="nodePercentChange"></v-text-field>
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 付款节点</div>
          </v-col>
          <v-col cols="4">
            <v-text-field dense v-model="nodes.Data.name" :rules="$requiredRules"></v-text-field>
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 金额(元)</div>
          </v-col>
          <v-col cols="4">
            <v-text-field dense v-model="nodes.Data.money" :rules="$requiredRules" @input="nodeMoneyChange"></v-text-field>
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 定金/首付款</div>
          </v-col>
          <v-col cols="4">
            <v-radio-group v-model="nodes.Data.isFirst" row dense :rules="$requiredRules">
              <v-radio label="是" :value="true"></v-radio>
              <v-radio label="否" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 关联项目节点</div>
          </v-col>
          <v-col cols="4">
            <v-select :rules="$requiredRules" dense item-text="name" item-value="id" :menu-props="{ bottom: true, offsetY: true }" v-model="nodes.Data.pmNodePlanId" :items="nodes.sysCodeGroups.find((item) => item.codeType == 'PMNodePlanId').sysCodes"></v-select>
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 备注</div>
          </v-col>
          <v-col cols="10" style="height: auto">
            <v-textarea auto-grow dense v-model="nodes.Data.remark" row-height="15" rows="2"> </v-textarea>
          </v-col>
          <v-col cols="4"></v-col>
          <v-col cols="12" class="d-flex" style="margin: 8px 0; justify-content: center">
            <div>
              <v-btn depressed color="primary" outlined style="margin-right: 10px" @click="deleteNodes" v-if="nodes?.Data.id != 0 && !nodes.Data.bmContractinGatherings.length && !nodes.Data.bmContractinInvoices.length && !readonly" :disabled="nodes.Type == '新增' || readonly"> 删除 </v-btn>
            </div>
            <div>
              <v-btn depressed color="primary" v-if="!readonly" @click="sumbitNodes"> 保存 </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </my-alert>
    <my-alert :value.sync="PopupinvoiceMoney" :title="'发票信息'" :width="'900'">
      <v-row>
        <v-col cols="12" style="height: 5px"></v-col>
        <v-col cols="1"></v-col>
        <v-col cols="3">
          <div style="display: flex">
            <div class="text-right line_44">付款节点</div>
            <div class="line_44" style="color: red; margin-left: 20px">
              {{ invoiceMoney.Name }}
            </div>
          </div>
        </v-col>
        <v-col cols="7"></v-col>
        <v-col cols="1">
          <v-btn color="primary" v-if="!readonly" @click="GetInvoiceMoney({ id: 0, title: '新增' })">新增</v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table :headers="invoiceMoney.Title" :items="invoiceMoney.List" hide-default-footer @click:row="GetInvoiceMoney" class="elevation-1">
            <template v-slot:[`item.date`]="props">
              {{ props.item.date | publicDate }}
            </template>
            <template v-slot:[`item.money`]="props">
              <td class="text-end hover">
                {{ props.item.money | formatMoney }}
              </td>
            </template>
            <template v-slot:[`item.state`]="props">
              {{
                [
                  { lable: '未审批', value: 0 },
                  { lable: '审批中', value: 1 },
                  { lable: '发票正常', value: 2 },
                  { lable: '发票作废', value: 4 },
                ].find((item) => item.value == props.item.state).lable
              }}
            </template>
            <template v-slot:[`body.append`]>
              <tr>
                <td class="text-start font-weight-bold">合计</td>
                <td class="text-end">
                  {{ totilPmList(invoiceMoney.List, 'money') | formatMoney }}
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
          <v-col cols="12" style="height: 20px"></v-col>
        </v-col>
      </v-row>
    </my-alert>
    <my-alert :value.sync="popupinGathering" :title="'收款信息'" :width="'900'">
      <v-row>
        <v-col cols="12" style="height: 5px"></v-col>
        <v-col cols="1"></v-col>
        <v-col cols="3">
          <div style="display: flex">
            <div class="text-right line_44">付款节点</div>
            <div class="line_44" style="color: red; margin-left: 20px">
              {{ invoiceMoney.Name }}
            </div>
          </div>
        </v-col>
        <v-col cols="7"></v-col>
        <v-col cols="1">
          <v-btn color="primary" v-if="!readonly" @click="GetInGathering({ id: 0, title: '新增' })">新增</v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table :headers="inGathering.Title" :items="inGathering.List" @click:row="GetInGathering" hide-default-footer class="elevation-1">
            <template v-slot:[`item.date`]="props">
              {{ props.item.date | publicDate }}
            </template>
            <template v-slot:[`item.money`]="props">
              <td class="text-end hover">
                {{ props.item.money | formatMoney }}
              </td>
            </template>
            <template v-slot:[`body.append`]>
              <tr>
                <td class="text-start font-weight-bold">合计</td>
                <td class="text-end">
                  {{ totilPmList(inGathering.List, 'money') | formatMoney }}
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" style="height: 20px"></v-col>
      </v-row>
    </my-alert>
    <my-alert :value.sync="invoiceMoney.Flag" :title="'发票信息'" :width="'900'">
      <v-form ref="PopupinvoiceMoney" lazy-validation>
        <v-row>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 开票日期</div>
          </v-col>
          <v-col cols="4">
            <DatePicker v-model="invoiceMoney.Data.date" />
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 金额</div>
          </v-col>
          <v-col cols="4">
            <v-text-field dense v-model="invoiceMoney.Data.money" :rules="$requiredRules"></v-text-field>
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44">发票号</div>
          </v-col>
          <v-col cols="4">
            <v-text-field dense v-model="invoiceMoney.Data.code"></v-text-field>
          </v-col>
          <v-col cols="6"></v-col>
          <v-col cols="4"> </v-col>
          <v-col cols="12" class="d-flex" style="justify-content: center; margin-bottom: 5px">
            <v-btn :disabled="invoiceMoney.Type == '新增'" color="primary" outlined v-if="invoiceMoney.Data.qiChu && !readonly" style="margin-right: 10px" @click="deleteInvoiceMoney"> 删除 </v-btn>
            <v-btn depressed color="primary" v-if="(invoiceMoney.Data.qiChu || invoiceMoney.Type == '新增') && !readonly" @click="submitInvoiceMoney"> 保存 </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </my-alert>
    <my-alert :value.sync="inGathering.Flag" :title="'收款信息'" :width="'900'">
      <v-form ref="inGathering" lazy-validation>
        <v-row>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 收款日期</div>
          </v-col>
          <v-col cols="4">
            <DatePicker v-model="inGathering.Data.date" />
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 金额(元)</div>
          </v-col>
          <v-col cols="4">
            <v-text-field dense v-model="inGathering.Data.money" :rules="$requiredRules"></v-text-field>
          </v-col>
          <v-col cols="2">
            <div class="text-right line_44">备注</div>
          </v-col>
          <v-col cols="4">
            <v-text-field dense v-model="inGathering.Data.remark"></v-text-field>
          </v-col>
          <v-col cols="6"> </v-col>
          <v-col cols="5"></v-col>
          <v-col cols="12" class="d-flex" style="justify-content: center; margin-bottom: 5px">
            <v-btn :disabled="inGathering.Type == '新增'" color="primary" outlined v-if="inGathering.Data.qiChu && !readonly" @click="deleteInGathering" style="margin-right: 10px"> 删除 </v-btn>
            <v-btn depressed color="primary" v-if="(inGathering.Data.qiChu || inGathering.Type == '新增') && !readonly" @click="submitInGathering"> 保存 </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </my-alert>
    <choose-leadership :flag.sync="flag" :deptManagers="userList" @setleader="setleader"></choose-leadership>
  </div>
</template>
<script>
let api = {}
import * as BMContractinChange from '../../api/BMContractinChange'
import * as BMContractinGathering from '../../api/BMContractinGathering'
import * as BMContractinInvoice from '../../api/BMContractinInvoice'
import * as BMContractinPaymentNode from '../../api/BMContractinPaymentNode'
import * as PMProject from '../../api/PMProject'
api = {
  ...PMProject,
  ...BMContractinChange,
  ...BMContractinInvoice,
  ...BMContractinGathering,
  ...BMContractinPaymentNode,
}
function TableObject(obj) {
  for (let key in obj) {
    this[key] = obj[key]
  }
}
export default {
  name: 'FiltContractInformation',
  props: {
    dialog: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    resSysCodeGroups: {
      type: Array,
    },
    resMainData: {
      type: Object,
    },
    readonly: {
      type: Boolean,
    },
  },
  data() {
    return {
      data: [], //请求回来的值
      ContractAmount: {
        Title: [
          { text: '序号', value: 'xuhao' },
          { text: '设计区域', value: 'quyu' },
          { text: '总价(元)', value: 'zongjia' },
          { text: '单价(元)', value: 'danjia' },
          { text: '面积(㎡)', value: 'mianji' },
          { text: '优惠单价(元)', value: 'youhui' },
          { text: '优惠总价(元)', value: 'youhuizong' },
          { text: '备注', value: 'remark' },
        ],
        List: [
          {
            xuhao: 1,
            quyu: '样板间15栋108室',
            zongjia: '362,400.00',
            danjia: '1,200.00',
            mianji: '302.00',
            youhui: '0.00',
            youhuizong: '0.00',
            remark: '',
          },
        ],
        flag: false,
      },
      row1: 'radio-1', //虚拟合同的值
      modalData: false, //日期选择器的弹出框
      Data1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      agreement: {}, //新增合同的对象
      newProJect: false, //新项目弹窗
      Adelete: false, //新加报价和修改报价有没用删除框的区别 true代表有
      invoiceMoney: {}, //开票弹窗的数据
      PopupinvoiceMoney: false, //开票弹窗
      popupinGathering: false, //到款弹窗
      inGathering: {}, //到款的数据
      flagAgreement: false, //新增弹窗
      xinzengTitle: [
        { text: '协议编号', value: 'code', width: 250 },
        { text: '协议名称', value: 'name', width: 400 },
        { text: '签订日期', value: 'signDate', width: 110 },
        { text: '金额', value: 'money', width: 130, align: 'right', dataType: 'money', sum: true },
        { text: '协议内容', value: 'content' },
      ],
      xinzengData: {},
      mydialog: false,
      mainData: {},
      sysCodeGroups: [],
      plan: {
        Title: [
          { text: '排序', value: 'sort', width: 80, align: 'center' },
          { text: '付款节点名称', value: 'name', width: 200, align: 'left' },
          { text: '比例', value: 'percent', width: 120, align: 'right' },
          { text: '金额', value: 'money', width: 120, align: 'right' },
          { text: '开票', value: 'invoiceMoney', width: 120, align: 'right' },
          { text: '到款', value: 'gatheringMoney', width: 120, align: 'right' },
          { text: '开票未回', value: 'received', width: 120, align: 'right' },
          {
            text: '到款比例',
            value: 'gatheringPercent',
            width: 220,
            align: 'center',
          },
          { text: '备注', value: 'remark' },
        ],
        List: [],
        data: {},
        type: false,
        vaild: true,
      },
      pmProjectRole: [],
      userIndex: -1,
      userList: [],
      flag: false,
      plansysCodeGroups: [],
      agreementTitle: '',
      inGatheringFlag: false,
      nodes: {},
      vaild: true,
      objj: {},
    }
  },
  async created() {
    this.exampleInvoiceMoney()
    this.exampleInGathering()
    this.exampleInvoiceMoneyNodes()
    this.mainData = this.resMainData
    this.sysCodeGroups = this.resSysCodeGroups
    this.plan.List = this.resMainData.bmContractin.pmProjects
  },
  methods: {
    counted(str) {
      return this.$math.number(this.$math.evaluate(str))
    },
    totilPmList(list, code) {
      let num = 0
      list.forEach((item) => {
        num = this.counted(`${num}+${item[code]}`)
      })
      return num
    },
    exampleInvoiceMoney() {
      let obj = {
        Title: [
          { text: '开票日期', value: 'date' },
          { text: '金额(元)', value: 'money' },
          { text: '发票号', value: 'code' },
          { text: '备注', value: 'remark' },
          { text: '发票状态', value: 'state' },
        ],
        List: [],
        Id: '',
        Name: '',
        Type: '',
        Flag: false,
        Data: {},
        GetList: api.BMContractinInvoiceGetList,
        Get: api.BMContractinInvoiceGet,
        Add: api.BMContractinInvoicePostAdd,
        Update: api.BMContractinInvoicePostUpdate,
        Delete: api.BMContractinInvoiceDelete,
      }
      this.invoiceMoney = new TableObject(obj)
    },
    exampleInGathering() {
      let obj = {
        Title: [
          { text: '回款日期', value: 'date' },
          { text: '金额(元)', value: 'money' },
          { text: '备注', value: 'remark' },
        ],
        List: [],
        Name: '',
        Id: '',
        Type: '',
        Flag: false,
        Data: {},
        GetList: api.BMContractinGatheringGetList,
        Get: api.BMContractinGatheringGet,
        Add: api.BMContractinGatheringPostAdd,
        Update: api.BMContractinGatheringPostUpdate,
        Delete: api.BMContractinGatheringDelete,
      }
      this.inGathering = new TableObject(obj)
    },
    exampleInvoiceMoneyNodes() {
      let obj = {
        Id: '',
        Type: '',
        Flag: false,
        Data: {},
        sysCodeGroups: [],
        Get: api.BMContractinPaymentNodeGet,
        Add: api.BMContractinPaymentNodePostAdd,
        Update: api.BMContractinPaymentNodePostUpdate,
        Delete: api.BMContractinPaymentNodeDelete,
      }
      this.nodes = new TableObject(obj)
    },

    SaveAmount() {
      this.agreement.zongjia = this.agreement.mianji * this.agreement.danjia - 1 * this.agreement.youhui || 1 * this.agreement.youhuizong
      this.agreement.xuhao = this.ContractAmount.List.length + 1
      this.ContractAmount.List.push(this.agreement)
      this.agreement = {}
      this.ContractAmount.flag = false
    },
    alertXiangMu(item) {
      this.ContractAmount.flag = true
      this.agreement = item
      if (item != '') {
        this.Adelete = true
      } else {
        this.Adelete = false
      }
    },
    async alertinvoiceMoney(item) {
      this.invoiceMoney.List = await this.invoiceMoney.GetList(item.id)
      this.invoiceMoney.Name = item.name
      this.invoiceMoney.Id = item.id
      this.PopupinvoiceMoney = true
    },
    async sumbitNodes() {
      let a = this.$refs.form.validate()
      if (a === false) {
        this.$message({ type: 'error', message: '有必填字段未填写' })

        return
      }
      let res = await this.Git(this.nodes.Add, this.nodes.Update, this.nodes.Type, this.nodes.Data)
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.nodes.Flag = false
      }
    },
    async alertNodes(item) {
      let res = await this.nodes.Get(item.id)
      this.nodes.Data = res.mainData
      this.nodes.sysCodeGroups = res.sysCodeGroups
      this.nodes.Type = '更新'
      this.nodes.Flag = true
    },
    async addNodes(id) {
      let res = await this.nodes.Get(0, id)
      this.nodes.Data = res.mainData
      this.nodes.sysCodeGroups = res.sysCodeGroups
      this.nodes.Type = '新增'
      this.nodes.Flag = true
    },
    async deleteNodes() {
      let res = await this.Git(this.nodes.Delete, null, '删除', this.nodes.Data.id)
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.nodes.Flag = false
      }
    },
    async alertgatheringMoney(item) {
      this.inGathering.List = await this.inGathering.GetList(item.id)
      this.popupinGathering = true
      this.inGathering.Id = item.id
      this.inGathering.Name = item.name
    },
    format(day) {
      return day.split('-')[2]
    },
    getList(type) {
      if (type) {
        this.mydialog = false
      }
      this.$emit('getList')
    },
    setUpPlan(res) {
      this.plan.data = res.mainData.pmProject
      this.plansysCodeGroups = res.sysCodeGroups
      this.pmProjectRole = res.mainData.pmProjectRole.map((item) => {
        item.userList = item.users.reduce((pre, cur, index) => {
          if (index == item.users.length) return cur.userName
          return pre ? pre + ',' + cur.userName : cur.userName
        }, '')
        return item
      })
      if (this.plan.data.id == 0) {
        this.plan.data.pmMoney = null
        this.plan.data.unitPrice = null
        this.plan.data.taxRate = null
        this.plan.data.pmArea = null
        this.plan.data.realDesignArea = null
      }
    },
    async addPlan() {
      this.plan.type = '新增'
      let res = await api.PMProjectGetId(0, this.resMainData.bmContractin.id)
      this.setUpPlan(res)
      this.newProJect = true
    },
    selectLingDao(index) {
      this.userIndex = index
      this.userList = this.pmProjectRole[index].users
      this.flag = true
    },
    setleader(data) {
      this.pmProjectRole[this.userIndex].users = data
      this.pmProjectRole[this.userIndex].userList = data.reduce((pre, cur, index) => {
        if (index == data.length) return cur.userName
        return pre ? pre + ',' + cur.userName : cur.userName
      }, '')
      this.flag = false
    },
    async setPlan() {
      let a = this.$refs.newProJect.validate()
      if (a === false) {
        this.$message({ type: 'error', message: '有必填字段未填写' })
        return
      }
      let res = await this.Git(api.PMProjectPostAdd, api.PMProjectPostUpdate, this.plan.type, {
        pmProject: this.plan.data,
        pmProjectRole: this.pmProjectRole,
      })
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.newProJect = false
        this.$emit('getList')
      }
    },
    async UpdatePlan(id) {
      this.plan.type = '更新'
      let res = await api.PMProjectGetId(id)
      this.setUpPlan(res)
      this.newProJect = true
    },
    async deletePlan() {
      let res = await this.Git(api.deletePMProject, null, '删除', this.plan.data.id)
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.newProJect = false
      }
    },
    async setAgreement(id) {
      this.agreementTitle = '更新'
      let res = await api.BMContractinChangeGetId(id)
      this.agreement = res.mainData
      this.flagAgreement = true
      this.$nextTick((a) => {
        this.$refs.flagAgreement?.resetValidation()
      })
    },
    async sumbitAgreement() {
      let a = this.$refs.flagAgreement.validate()
      if (a === false) {
        this.$message({ type: 'error', message: '有必填字段未填写' })

        return
      }
      let res = await this.Git(api.BMContractinChangePostAdd, api.BMContractinChangePostUpdate, this.agreementTitle, {
        ...this.agreement,
        bcId: this.resMainData.bmContractin.id,
      })
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.agreement = {}
        this.flagAgreement = false
      }
    },
    addAgreement() {
      this.flagAgreement = true
      this.$nextTick(() => {
        this.$refs.flagAgreement?.resetValidation()
      })
      this.agreement = {}
      this.agreementTitle = '新增'
    },
    async deleteAgreement() {
      let res = await this.Git(api.deleteBMContractinChange, null, '删除', this.agreement.id)
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.flagAgreement = false
        this.agreement = {}
      }
    },
    Git(addOrDelete, update, type, data) {
      let res
      return new Promise(async (resolve, reject) => {
        if (type == '新增') {
          res = await addOrDelete(data)
        } else if (type == '更新') {
          res = await update(data)
        } else if (type == '删除') {
          res = await addOrDelete(data)
        } else {
          alert('type不正确')
          return
        }
        if (res) {
          this.$message({ type: 'success', message: type + '成功' })
          resolve(true)
        } else {
          // this.$message({ type: "success", message: type + "失败" });
          resolve(false)
        }
      })
    },
    async GetInvoiceMoney(item) {
      this.invoiceMoney.Type = item.title ? '新增' : '更新'
      let res = await this.invoiceMoney.Get(item.id, item.title ? this.invoiceMoney.Id : '')
      this.invoiceMoney.Data = res.mainData
      this.invoiceMoney.Flag = true
      this.$nextTick(() => {
        this.$refs.PopupinvoiceMoney?.resetValidation()
      })
    },
    async GetInGathering(item) {
      this.inGathering.Type = item.title ? '新增' : '更新'
      let res = await this.inGathering.Get(item.id, item.title ? this.inGathering.Id : '')
      this.inGathering.Data = res.mainData
      this.inGathering.Flag = true
      this.$nextTick(() => {
        this.$refs.inGathering?.resetValidation()
      })
    },
    async submitInvoiceMoney() {
      let a = this.$refs.PopupinvoiceMoney.validate()
      if (a === false) {
        this.$message({ type: 'error', message: '有必填字段未填写' })

        return
      }
      let res = await this.Git(this.invoiceMoney.Add, this.invoiceMoney.Update, this.invoiceMoney.Type, this.invoiceMoney.Data)
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.invoiceMoney.List = await this.invoiceMoney.GetList(this.invoiceMoney.Id)
        this.invoiceMoney.Flag = false
      }
    },
    async deleteInvoiceMoney() {
      let res = await this.Git(this.invoiceMoney.Delete, null, '删除', this.invoiceMoney.Data.id)
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.invoiceMoney.List = await this.invoiceMoney.GetList(this.invoiceMoney.Id)
        this.invoiceMoney.Flag = false
      }
    },
    async submitInGathering() {
      let a = this.$refs.inGathering.validate()
      if (a === false) {
        this.$message({ type: 'error', message: '有必填字段未填写' })

        return
      }
      let res = await this.Git(this.inGathering.Add, this.inGathering.Update, this.inGathering.Type, this.inGathering.Data)
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.inGathering.List = await this.inGathering.GetList(this.inGathering.Id)
        this.inGathering.Flag = false
      }
    },
    async deleteInGathering() {
      let res = await this.Git(this.inGathering.Delete, null, '删除', this.inGathering.Data.id)
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.inGathering.List = await this.inGathering.GetList(this.inGathering.Id)
        this.inGathering.Flag = false
      }
    },
    updateData(id) {
      if (id) {
        this.$emit('updateData', { id })
      } else {
        this.$emit('updateData', this.resMainData.bmContractin)
      }
    },
    nodePercentChange() {
      let pm = this.resMainData.bmContractin.pmProjects.filter((item) => item.id == this.nodes.Data.pmId)[0]
      this.nodes.Data.money = this.$math.round(this.counted(`${pm.pmMoney}*${this.nodes.Data.percent}/100`), 2)
    },
    nodeMoneyChange() {
      let pm = this.resMainData.bmContractin.pmProjects.filter((item) => item.id == this.nodes.Data.pmId)[0]
      this.nodes.Data.percent = pm.pmMoney ? this.$math.round(this.counted(`${this.nodes.Data.money}/${pm.pmMoney}*100`), 2) : 0
    },
    async ComputeNodeMoney(id) {
      let res = await api.ComputeNodeMoney(id)
      if (res) {
        this.$emit('updateData', this.resMainData.bmContractin)
        this.nodes.Flag = false
      }
    },
    nodesMoneyTotal(pm) {
      return pm.bmContractinPaymentNodes.reduce((sum, item) => {
        return this.counted(`${sum}+${item.money}`)
      }, 0)
    },
  },

  computed: {
    totalinvoiceMoneyList() {
      let list = []
      this.invoiceMoney.Title.forEach((item, index) => {
        if (item.value == 'money') {
          this.invoiceMoney.List.forEach((item) => {
            list[index] = item.money
          })
        } else {
          list[index] = ''
        }
      })
      list.shift()
      return list
    },
    totalinGatheringList() {
      let list = []
      this.inGathering.Title.forEach((item, index) => {
        if (item.value == 'money') {
          this.inGathering.List.forEach((item) => {
            list[index] = item.money
          })
        } else {
          list[index] = ''
        }
      })
      list.shift()
      return list
    },
  },

  mounted() {
    if (this.$attrs.maodian) {
      this.$nextTick(() => {
        document.querySelector('#myalert-text').scrollTo({
          top: document.getElementById(this.$attrs.maodian).offsetTop - 80,
          behavior: 'smooth',
        })
      })
    }
  },
  watch: {
    dialog: {
      handler() {
        this.mydialog = this.dialog
      },
      immediate: true,
    },
    mydialog() {
      this.$emit('changeDialog', this.mydialog)
    },
    resMainData: {
      handler() {
        this.mainData = this.resMainData
        this.sysCodeGroups = this.resSysCodeGroups
        this.plan.List = this.resMainData.bmContractin.pmProjects
      },
      deep: true,
    },
  },
  filters: {
    findPlan(Id, key) {
      return this.plansysCodeGroups.find((item) => item.codeType == key).sysCodes.filter((item) => item.parentId == null && item.id == Id).codeName
    },
    ProjectExt(val, id, key, sysCodeGroups) {
      return sysCodeGroups.find((item) => item.codeType === 'ProjectExt').sysCodes.find((item) => item.id == id)[key]
    },
  },
}
</script>

<style lang="scss" scoped>
.m0 {
  margin-top: 0;
}

td {
  padding: 0 12px !important;
}

.m-30 {
  margin-top: -63.5px;
  margin-right: 10px;
}

.v-data-footer__select {
  margin-left: 0 !important;
}

.v-data-footer__icons-before,
.v-data-footer__icons-after {
  display: none;
}

::v-deep .menuable__content__active {
  top: 160px !important;
}

::v-deep .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.sosuo {
  margin-left: 20px;
}

::v-deep .v-select__slot {
  font-size: 14px;
}

::v-deep .v-data-table-header tr th {
  color: #302c2c !important;
  font-weight: 400 !important;
}

.plan {
  background: #f5f8fd;
  border-left: 5px solid #8bb4e7;
  padding-bottom: 15px !important;
}

.noBorderPlan {
  background: #f5f8fd;
  padding-bottom: 15px !important;
}

.plan .theme--light.v-data-table {
  background-color: transparent;
}

.plan .elevation-1 {
  box-shadow: none !important;
}

.plan tbody tr:nth-of-type(even) {
  background-color: transparent !important;
}
.font-red {
  color: #e91e63;
}
</style>
