
import request from "../utility/http"
/**
 * 请求 补充协议的数据
 * @param {}  
 * @returns 返回 补充协议的数据
 */
export function BMContractinChangeGetList () {
    return request.get('/api/BMContractinChange/GetList')
}

/**
 *  请求某个补充协议的信息
 * @param {Number} id 传入补充协议id
 * @returns 返回补充协议信息
 */

export function BMContractinChangeGetId (id, bcId) {
    return request.get(`/api/BMContractinChange/Get/${id}` + (bcId ? `?bcId=${bcId}` : ''))
}

/**
 * 新增补充协议
 * @param {Object} data 传入新增的补充协议信息
 * @returns 返回是否新增成功
 */
export function BMContractinChangePostAdd (data) {
    return request.post('/api/BMContractinChange/Add', data)
}

/**
 * 更新补充协议信息
 * @param {Object} data 传入补充协议更新后的信息
 * @returns 返回是否更新成功
 */

export function BMContractinChangePostUpdate (data) {
    return request.post('/api/BMContractinChange/Update', data)
}

/**
 * 
 * @param {*} data 
 * @returns 删除一个补充协议
 */
export function deleteBMContractinChange (id) {
    return request.delete('/api/BMContractinChange/Delete/' + id + '')
}

