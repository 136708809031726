<template>
  <div>
    <v-form ref="form" lazy-validation v-model="vaild">
      <v-row>
        <v-col cols="12" class="pt-8"> </v-col>
        <v-col cols="12">
          <!-- <v-divider></v-divider> -->
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44">合同编号</div>
        </v-col>
        <v-col cols="2">
          <v-text-field dense v-model="data.code" @input="codeChange(data)"></v-text-field>
        </v-col>
        <v-col cols="1">
          <div class="text-right line_44">外部合同号</div>
        </v-col>
        <v-col cols="1">
          <v-text-field dense v-model="data.code2"></v-text-field>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44"><span style="color: #e02222">*</span> 合同名称</div>
        </v-col>
        <v-col cols="4">
          <v-text-field :rules="$requiredRules" dense v-model="data.name"></v-text-field>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44">合同签订金额(元)</div>
        </v-col>
        <v-col cols="1">
          <v-text-field dense v-model="data.signMoney" disabled></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="line_44">
            {{ data.signMoney | smallToBig }}
          </div>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44">合同有效金额(元)</div>
        </v-col>
        <v-col cols="1">
          <v-text-field dense v-model="data.money" disabled></v-text-field>
        </v-col>
        <v-col cols="3">
          <div class="line_44">{{ data.money | smallToBig }}</div>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44"><span style="color: #e02222">*</span> 项目来源</div>
        </v-col>
        <v-col cols="2">
          <v-radio-group dense v-model="data.source" row :rules="$requiredRules">
            <v-radio :label="item.codeName" :value="item.codeId" v-for="item in CodeGroups.find((item) => item.codeType == 'Source').sysCodes" :key="item.codeName"></v-radio>
          </v-radio-group>
        </v-col>
        <template v-if="data.source == 10">
          <!-- 没有这个字段 -->
          <v-col cols="1">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 投标日期</div>
          </v-col>
          <v-col cols="1">
            <v-menu ref="menu" v-model="modalData1" offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense :value="data.bidDate | publicDate" readonly placeholder="这是必填字段" :rules="$requiredRules" v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="data.bidDate" @input="modalData1 = false" no-title scrollable :day-format="format"> </v-date-picker>
            </v-menu>
          </v-col>
        </template>
        <v-col cols="2" v-else> </v-col>
        <v-col cols="2">
          <div class="text-right line_44"><span style="color: #e02222">*</span> 虚拟合同</div>
        </v-col>
        <v-col cols="4">
          <v-radio-group dense v-model="data.isVirtual" row :rules="$requiredRules">
            <v-radio label="是" :value="true"></v-radio>
            <v-radio label="否" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44"><span style="color: #e02222">*</span> 签订状态</div>
        </v-col>
        <v-col cols="4">
          <v-radio-group dense v-model="data.signStatus" row :rules="$requiredRules">
            <v-radio :label="item.codeName" :value="item.codeId" v-for="item in CodeGroups.find((item) => item.codeType == 'SignStatus').sysCodes" :key="item.codeId"></v-radio>
          </v-radio-group>
        </v-col>
        <template v-if="data.signStatus == 30">
          <v-col cols="2">
            <div class="text-right line_44"><span style="color: #e02222">*</span> 履约状态</div>
          </v-col>
          <v-col cols="4">
            <v-radio-group dense v-model="data.status" row :rules="$requiredRules" @change="statusChange()">
              <v-radio :label="item.codeName" :value="item.codeId" v-for="item in CodeGroups.find((item) => item.codeType == 'Status').sysCodes" :key="item.codeId"></v-radio>
            </v-radio-group>
          </v-col>
        </template>
        <v-col cols="6" v-else></v-col>
        <v-col cols="2">
          <div class="text-right line_44"><span style="color: #e02222">*</span> 是否拓展</div>
        </v-col>
        <v-col cols="4">
          <v-radio-group dense v-model="data.isExpand" row :rules="$requiredRules">
            <v-radio label="是" :value="true"></v-radio>
            <v-radio label="否" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44"><span style="color: #e02222">*</span> {{ data.signStatus == '30' ? '签订日期' : '统计日期' }}</div>
        </v-col>
        <v-col cols="4">
          <v-menu ref="menu" v-model="modalData" offset-y transition="scale-transition" min-width="auto" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense :value="data.signDate | publicDate" readonly placeholder="这是必填字段" v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="data.signDate" @input="modalData = false" no-title :day-format="format" scrollable> </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44">签约主体</div>
          <!-- 没有这个字段 -->
        </v-col>
        <v-col cols="4">
          <v-select dense v-model="data.partyBId" item-text="codeName" item-value="codeId" :menu-props="{ bottom: true, offsetY: true }" :items="CodeGroups.find((item) => item.codeType == 'PartyBId').sysCodes.filter((item) => !item.parentId)"></v-select>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44">登记人</div>
        </v-col>
        <v-col cols="4">
          <v-text-field dense v-model="data.addUserName" disabled></v-text-field>
        </v-col>
        <v-row>
          <template v-for="(item, index) in bmContractinRole">
            <v-col cols="2" :key="index">
              <div class="text-right line_44"><span style="color: #e02222" v-if="item.isRequired">*</span> {{ item.roleName }}</div>
            </v-col>
            <v-col cols="1" class="d-flex" :key="item.roleName">
              <v-text-field dense :rules="!item.isRequired || $requiredRules" v-model="item.userList" disabled></v-text-field>
              <div class="line_44 text-right peopleRight">
                <v-btn tile color="primary" :disabled="readonly" class="people" @click="selectLingDao(index)">
                  <v-icon dense>mdi-account</v-icon>
                </v-btn>
                <!-- <v-icon @click="selectLingDao">mdi-account </v-icon> -->
              </div>
            </v-col>
          </template>
        </v-row>

        <v-col cols="2">
          <div class="text-right line_44"><span style="color: #e02222">*</span> 合同甲方</div>
        </v-col>
        <v-col cols="4">
          <v-autocomplete v-model.number="data.partyAId" item-text="unitName" item-value="id" :menu-props="{ bottom: true, offsetY: true }" :items="CodeGroups.find((item) => item.codeType == 'PartyAId').sysCodes" dense :rules="$requiredRules"></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44"><span style="color: #e02222">*</span> 甲方联系人</div>
        </v-col>
        <v-col cols="1">
          <v-select dense item-text="linkmanName" item-value="id" :menu-props="{ bottom: true, offsetY: true }" v-model="data.partyAContactId" :items="linkMans" :rules="$requiredRules"></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field dense :value="linkmanPhone" disabled prepend-icon="colsi-close"></v-text-field>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44">项目地址</div>
        </v-col>
        <v-col cols="4">
          <el-cascader ref="cascader" size="large" style="margin-top: -2px" :props="{ checkStrictly: true }" :options="options" v-model="selectedOptions" filterable @change="handleChange"> </el-cascader>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44">详细地址</div>
        </v-col>
        <v-col cols="4">
          <v-text-field dense v-model="data.address"></v-text-field>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44">项目重要信息</div>
        </v-col>
        <v-col cols="10" style="height: auto">
          <v-textarea dense auto-grow rows="1" row-height="15" v-model="data.remark"> </v-textarea>
        </v-col>
        <v-col cols="2">
          <div class="text-right line_44">开票信息</div>
        </v-col>
        <v-col cols="1">
          <div class="text-right line_44">发票类型</div>
        </v-col>
        <v-col cols="1">
          <v-select dense v-model="data.invoiceType" item-text="codeName" item-value="codeId" :menu-props="{ bottom: true, offsetY: true }" :items="CodeGroups.find((item) => item.codeType == 'InvoiceType').sysCodes"></v-select>
        </v-col>
        <v-col cols="1">
          <div class="text-right line_44">发票服务名称</div>
        </v-col>
        <v-col cols="2">
          <v-text-field dense v-model="data.invoiceServiceName"></v-text-field>
        </v-col>
        <v-col cols="1">
          <div class="text-right line_44">发票备注</div>
        </v-col>
        <v-col cols="4">
          <v-text-field dense v-model="data.invoiceRemark"></v-text-field>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="1">
          <div class="text-right line_44">税率(%)</div>
        </v-col>
        <v-col cols="1">
          <v-text-field dense v-model="data.invoiceTaxRate"></v-text-field>
        </v-col>
        <v-col cols="1">
          <div class="text-right line_44">特殊要求</div>
        </v-col>
        <v-col cols="7">
          <v-text-field dense v-model="data.invoiceSpecialRequirement"></v-text-field>
        </v-col>
        <v-col cols="2" v-if="data.id !== 0">
          <div class="text-right line_44">附件</div>
        </v-col>
        <v-col cols="10" v-if="data.id !== 0">
          <cct-file-input :GuId="data.attachmentGuid"></cct-file-input>
        </v-col>

        <v-col cols="12" v-else></v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" class="d-flex" style="justify-content: center; margin: 8px 0">
        <el-popconfirm title="确定删除合同吗？" @confirm="DeleteContractDialog" :hide-icon="true" class="cursor mr-3" :ref="`popover-${title}`" popper-class="area_popper">
          <v-btn depressed outlined @click.native.stop :disabled="title == '新增合同' || readonly" slot="reference" type="success" color="primary">删除 </v-btn>
        </el-popconfirm>
        <div style="">
          <v-btn depressed color="primary" @click="Submit" :disabled="readonly"> 保存 </v-btn>
        </div>
      </v-col>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <choose-leadership :flag.sync="flag" :deptManagers="userList" @setleader="setleader" :only="1"></choose-leadership>

    <v-dialog v-model="confirmDialog" persistent max-width="300">
      <v-card>
        <v-card-title class="text-h5"> {{ Confirm.title }} </v-card-title>
        <v-card-text class="pa-4">{{ Confirm.content }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="Confirm.cancel"> 取消 </v-btn>
          <v-btn color="primary" text @click="Confirm.confirm"> 确认 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import * as api from '../../api/BMContractinGetList'
import { CodeToText, regionData, TextToCode } from '../../assets/json/element-china-area-data' //数据及代码来自https://github.com/Plortinus/element-china-area-data，省市数据有修改
export default {
  name: 'filtHead',
  props: {
    mainData: {
      type: Object,
    },
    title: {
      type: String,
    },
    sysCodeGroups: {
      type: Array,
    },
    readonly: {
      type: Boolean,
    },
  },
  data() {
    return {
      // deledialog: false,
      options: regionData,
      row: 'radio-1', //项目来源多选框选的值
      row1: 'radio-1', //虚拟合同的值
      modalData: false, //日期选择器的弹出框
      modalData1: false, //投标日期选择器的弹出框
      Data1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      TouBiaomodalData: '',
      data: {},
      bmContractinRole: [],
      flag: false,
      userList: [],
      userIndex: -1,
      CodeGroups: [],
      linkMans: [],
      linkmanPhone: '',
      fi: {},
      flagPartyAId: false,
      vaild: true,
      confirmDialog: false,
      Confirm: {
        title: '',
        content: '',
        confirm: () => {
          this.confirmDialog = false
        },
        cancel: () => {
          this.data.status = '10'
          this.confirmDialog = false
        },
      },
    }
  },
  computed: {
    selectedOptions: {
      set() {},
      get() {
        let text = this.data
        let options = []
        if (TextToCode[text.province]) {
          options.push(TextToCode[text.province].code)
          if (TextToCode[text.province][text.city]?.code) {
            options.push(TextToCode[text.province][text.city]?.code)
            if (TextToCode[text.province][text.city][text.prefecture]) {
              options.push(TextToCode[text.province][text.city][text.prefecture]?.code)
            }
          }
        }
        return options
      },
    },
    sumGathering() {
      let result = 0
      this.data.pmProjects?.forEach((item) => {
        result += item.bmContractinPaymentNodes.reduce((sum, i) => sum + i.gatheringMoney, 0)
      })
      return +result.toFixed(2)
    },
  },
  created() {
    this.data = this.mainData.bmContractin
    this.CodeGroups = this.sysCodeGroups
    this.bmContractinRole = this.mainData.bmContractinRole.map((item) => {
      item.userList = item.users.reduce((pre, cur, index) => {
        if (index == item.users.length) return cur.userName
        return pre ? pre + ',' + cur.userName : cur.userName
      }, '')
      return item
    })
    if (this.data.id == 0) {
      this.data.invoiceTaxRate = null
    }
  },
  methods: {
    codeChange(data) {
      if (data.id == 0) {
        data.code2 = data.code
      }
    },
    handleChange(value) {
      this.data.province = CodeToText[value[0]]
      this.data.city = CodeToText[value[1]]
      this.data.prefecture = CodeToText[value[2]]
    },
    format(day) {
      return day.split('-')[2]
    },
    selectLingDao(index) {
      this.userIndex = index
      this.userList = this.bmContractinRole[index].users
      this.flag = true
    },
    setleader(data) {
      this.bmContractinRole[this.userIndex].users = data
      this.bmContractinRole[this.userIndex].userList = data.reduce((pre, cur, index) => {
        if (index == data.length) return cur.userName
        return pre ? pre + ',' + cur.userName : cur.userName
      }, '')
      this.flag = false
    },
    async Submit() {
      let a = this.$refs.form.validate()
      if (a == false) {
        this.$message({ type: 'error', message: '有必填字段未填写' })

        return
      }
      let res
      if (this.title == '新增合同') {
        res = await api.sysCodeGroupPostAdd({
          bmContractin: this.data,
          bmContractinRole: this.bmContractinRole,
        })
      } else if (this.title == '更新数据') {
        res = await api.sysCodeGroupPostUpdate({
          bmContractin: this.data,
          bmContractinRole: this.bmContractinRole,
        })
      } else {
        alert('title类型不正确')
        return
      }
      if (res) {
        this.$message({
          type: 'success',
          message: `${this.title}成功`,
        })
        this.$emit('getList')
        this.$emit('updateData', res)
      } else {
        this.$message({ type: 'error', message: `${this.title}失败` })
      }
    },
    DeleteContractDialog() {
      this.deletecontract()
    },

    async deletecontract() {
      let res = await api.sysCodeGroupDelete(this.mainData.bmContractin.id)
      if (res) {
        this.$message({ type: 'success', message: '删除成功' })
        this.$emit('getList', true)
        this.d
      }
      //  else {
      // 	this.$message({ type: "error", message: "删除失败" });
      // }
    },
    statusChange() {
      if (this.data.status !== '10' && this.sumGathering < this.data.money) {
        this.Confirm.title = `确认${this.data.status === '20' ? '完成' : '终止'}合同？`
        this.Confirm.content = `合同未全部回款，目前已回 ${this.sumGathering} 元。`
        this.confirmDialog = true
      }
    },
  },
  watch: {
    heTongE() {
      // console.log(this.heTongE);
    },
    'data.partyAId'() {
      if (this.flagPartyAId == true) {
        this.data.partyAContactId = ''
      } else {
        this.flagPartyAId = true
      }
      this.linkMans =
        this.CodeGroups.find((key) => key.codeType == 'PartyAId').sysCodes.find((item) => {
          return item.id == this.data.partyAId
        })?.linkmans || []
    },
    'data.partyAContactId'() {
      this.linkmanPhone = this.linkMans.find((item) => item.id == this.data.partyAContactId)?.linkmanPhone || ''
    },
    'mainData.bmContractin': {
      handler() {
        this.data = this.mainData.bmContractin
        this.CodeGroups = this.sysCodeGroups
        this.bmContractinRole = this.mainData.bmContractinRole.map((item) => {
          item.userList = item.users.reduce((pre, cur, index) => {
            if (index == item.users.length) return cur.userName
            return pre ? pre + ',' + cur.userName : cur.userName
          }, '')
          return item
        })
      },
      deep: true,
    },
  },
  filters: {
    smallToBig(money) {
      if (money == undefined) return ''
      //  将数字金额转换为大写金额
      var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'] // 汉字的数字
      var cnIntRadice = ['', '拾', '佰', '仟'] // 基本单位
      var cnIntUnits = ['', '万', '亿', '兆'] // 对应整数部分扩展单位
      var cnDecUnits = ['角', '分', '毫', '厘'] // 对应小数部分单位
      var cnInteger = '整' // 整数金额时后面跟的字符
      var cnIntLast = '元' // 整数完以后的单位
      // 最大处理的数字
      var maxNum = 999999999999999.9999
      var integerNum // 金额整数部分
      var decimalNum // 金额小数部分
      // 输出的中文金额字符串
      var chineseStr = ''
      /* var parts // 分离金额后用的数组，预定义*/
      if (money === '') {
        return ''
      }
      money = parseFloat(money)
      if (money >= maxNum) {
        // 超出最大处理数字
        return '超出最大处理数字'
      }
      if (money === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger
        return chineseStr
      }

      // 四舍五入保留两位小数,转换为字符串
      money = Math.round(money * 100).toString()
      integerNum = money.substr(0, money.length - 2)
      const endTwoNum = money.substr(money.length - 2, money.length - 1) // 判断倒数第二位是否是0
      if (!endTwoNum || endTwoNum == 0) {
        decimalNum = '0' + money.substr(money.length - 1)
      } else {
        decimalNum = money.substr(money.length - 2)
      }

      // 获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0
        var IntLen = integerNum.length
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1)
          var p = IntLen - i - 1
          var q = p / 4
          var m = p % 4
          if (n === '0') {
            zeroCount++
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0]
            }
            // 归零
            zeroCount = 0
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
          }
          if (m === 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q]
          }
        }
        chineseStr += cnIntLast
      }
      // 小数部分
      if (decimalNum !== '') {
        var decLen = decimalNum.length
        for (let i = 0; i < decLen; i++) {
          const n = decimalNum.substr(i, 1)
          if (n !== '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i]
          }
        }
      }
      if (chineseStr === '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger
      } else if (decimalNum === '' || /^0*$/.test(decimalNum)) {
        chineseStr += cnInteger
      }
      return chineseStr
    },
    filterUser(user) {
      if (!user) return
      let str = ''
      user.forEach((item, index) => {
        if (index != user.length - 1) {
          str += item.userName + ','
        } else {
          str += item.userName
        }
      })
      return str
    },
  },
}
</script>

<style scoped>
.clear_hover {
  margin-left: 10px;
  font-size: 18px;
  cursor: pointer;
}

.clear_hover:hover {
  color: red;
}
::v-deep .el-input__suffix {
  display: none !important;
}
::v-deep .el-input--large {
  position: relative;
}
::v-deep .el-input--large::before {
  content: '\F035D';
  font: normal normal normal 24px/1 'Material Design Icons';
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  bottom: 13px;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
}
::v-deep .is-focus.el-input--large::before {
  content: '\F035D';
  font: normal normal normal 24px/1 'Material Design Icons';
  position: absolute;
  color: #e91e63;
  right: 0;
  bottom: 13px;
  z-index: 1;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  transform: rotate(180deg);
}
</style>
