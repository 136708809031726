<template>
  <v-dialog v-model="datas" scrollable persistent :fullscreen="option.fullscreen" :max-width="option.width">
    <v-card>
      <v-card-title class="headline" style="justify-content: space-between"
        ><span>{{ option.title }}</span>
        <v-spacer />
        <v-btn v-if="!option.fullscreen" @click="option.width = option.width + 100" class="close">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn v-if="!option.fullscreen" @click="option.width = option.width - 100" class="close">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
        <v-btn @click="changeDialog" class="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- 需要传递option -->
      <v-divider></v-divider>
      <v-card-text id="myDialog-text" :style="{ 'background-color': option.backgroundColor }">
        <div ref="contentdate">
          <!-- <v-col cols="12" class="pt-2"></v-col> -->
          <slot name="content"></slot>
          <!-- <v-col cols="12" class="pt-8"></v-col> -->
        </div>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions v-if="$slots.bottom">
        <v-col cols="12">
          <div class="flex" style="justify-content: center">
            <slot name="bottom"> </slot>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'myDialog',
  props: {
    option: {
      value: {
        type: Boolean,
      },
      ondialog: {
        type: Boolean,
      },
      width: {
        type: Number,
      },
      title: {
        type: String,
      },
      notTitle: {
        type: Boolean,
        default: false,
      },
      changeDialog: {
        type: Function,
      },
      backgroundColor: {
        type: String,
        default: '#fff',
      },
      fullscreen: {
        type: Boolean,
        default: false,
      },
    },
  },

  computed: {
    datas: {
      set: function () {},
      get: function () {
        return this.moedls()
      },
    },
  },

  methods: {
    moedls() {
      if (this.option.value == true) {
        return this.option.value
      } else if (this.option.ondialog == true) {
        return this.option.ondialog
      }
    },
    async changeDialog() {
      this.option.value = false
      this.option.ondialog = false
      this.option.text = false
    },
  },
}
</script>
<style lang="scss" scoped></style>
