<template>
  <v-row>
    <v-col cols="4">
      <tree style="max-height: 448px; overflow: auto" :flag="false" @transmitData="receiveData" :propgetList="sysdeptGetList" Name="deptName"></tree>
      <v-btn class="allment" color="primary" @click="getUser">全部用户</v-btn>
    </v-col>
    <v-col cols="1"></v-col>
    <v-col cols="7">
      <filt-list :yixuan="SelectedSun" :daixuan="daixuan" :only="only" ref="list"></filt-list>
    </v-col>
  </v-row>
</template>

<script>
import { sysdeptGetId, sysdeptGetList } from '../../api/sysdept'
import { getUserList1 } from '../../api/SysUser'
export default {
  name: 'SelectPersonnel',
  props: {
    Selected: {
      type: Array,
      default: () => [],
    },
    only: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      daixuan: [],
      isInit: true,
    }
  },
  created() {
    this.SelectedSun = this.Selected
  },
  mounted() {},
  computed: {
    sysdeptGetList() {
      return sysdeptGetList
    },
  },

  methods: {
    async receiveData(Data) {
      if (this.isInit) {
        await this.getUser()
        this.isInit = false
      } else {
        let data = await sysdeptGetId(Data.id)
        this.daixuan = data.sysUsers
        this.daixuan = this.daixuan.filter((item) => {
          return this.SelectedSun.every((SelectedSun) => SelectedSun.id != item.id)
        })
      }
    },
    getListValue() {
      return this.$refs.list._data.yixuansun
    },
    async getUser() {
      this.daixuan = await getUserList1()
      this.daixuan = this.daixuan.filter((item) => {
        return this.SelectedSun.every((SelectedSun) => SelectedSun.id != item.id)
      })
    },
  },
  watch: {
    Selected() {
      this.SelectedSun = this.Selected
    },
  },
}
</script>

<style lang="scss" scoped>
.allment {
  position: absolute;
  left: 17%;
  bottom: 21px;
  transform: translate(-50%, 0);
  height: 50px;
}
</style>
