
import request from "../utility/http"

/**
 * 请求发票信息列表
 * @param {Number} cpnid 付款节点的cpnid
 * @returns 返回列表数据
 */
export function BMContractinInvoiceGetList (cpnid) {
    return request.get("/api/BMContractinInvoice/GetListByCPNId/" + cpnid)
}

/**
 * 请求发票信息选项
 * @param {传入列表id} id 
 * @returns 返回发票信息选项
 */
export function BMContractinInvoiceGet (id, cpnid) {
    return request.get(`/api/BMContractinInvoice/Get/${id}` + (cpnid ? `?cpnid=${cpnid}` : ''))
}

/**
 * 新增发票信息
 * @param {传入新增的列表内容} data 
 * @returns 返回增加成功或者失败
 */
export function BMContractinInvoicePostAdd (data) {
    return request.post("/api/BMContractinInvoice/Add", data)
}

/**
 * 更新发票信息
 * @param {传入要更新的列表内容} data 
 * @returns 返回更新成功或者失败
 */
export function BMContractinInvoicePostUpdate (data) {
    return request.post("/api/BMContractinInvoice/Update", data)
}

/**
 * 删除发票信息的某个
 * @param {传入删除某个列表的id} id 
 * @returns 返回删除成功或者失败
 */
export function BMContractinInvoiceDelete (id) {
    return request.delete(`/api/BMContractinInvoice/Delete/${id}`)
}

