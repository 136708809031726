var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"pr-3",attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"dense":"","menu-props":{
        bottom: true,
        offsetY: true,
      },"rules":_vm.rules,"item-text":"codeName","item-value":"codeId","items":_vm.list.filter((item) => item.parentId === null)},on:{"change":_vm.changeTypeBig},model:{value:(_vm.typeBigId),callback:function ($$v) {_vm.typeBigId=$$v},expression:"typeBigId"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-autocomplete',{attrs:{"dense":"","menu-props":{
        bottom: true,
        offsetY: true,
      },"rules":_vm.rules,"item-text":"codeName","item-value":"codeId","items":_vm.list.filter((item) => item.parentId === _vm.typeBigId && _vm.typeBigId)},on:{"change":_vm.changeType},model:{value:(_vm.typeId),callback:function ($$v) {_vm.typeId=$$v},expression:"typeId"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }