export function formatMoney(val) {
  if (!val) return '0.00'
  let arr = (val * 1).toFixed(2).split('.')
  let int = arr[0]
  let dec = arr[1]
  return (int + '').replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '.' + dec
}
//获取金额整数部分
export function formatMoney0(val) {
  if (!val) return '0'
  let arr = (val * 1).toFixed(2).split('.')
  let int = arr[0]
  let dec = arr[1]
  return (int + '').replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
}
//获取金额小数部分
export function formatMoneyD(val) {
  if (!val) return '00'
  let arr = (val * 1).toFixed(2).split('.')
  let int = arr[0]
  let dec = arr[1]
  return dec
}
export function formatMoneyK(val) {
  if (!val) return 0 + 'K'
  return (Number(money) / 1000).toFixed(0) + 'K'
}
export function formatMoneyWan(val) {
  if (!val) return '0'
  return (val / 10000).toFixed()
}
export function publimycDate(val) {
  if (!val) return null
  let date = new Date(val)
  let y = date.getFullYear()
  let m = String(date.getMonth() + 1).padStart(2, '0')
  let d = String(date.getDate()).padStart(2, '0')
  let h = date.getHours()
  let i = date.getMinutes()
  let s = date.getSeconds()
  if (h < 10) {
    h = '0' + h
  }
  if (i < 10) {
    i = '0' + i
  }
  if (s < 10) {
    s = '0' + s
  }
  return y + '-' + m + '-' + d + ' ' + h + ':' + i + ':' + s
}

export function publicDate(val) {
  if (!val) return null
  let date = new Date(val)
  let y = date.getFullYear()
  let m = String(date.getMonth() + 1).padStart(2, '0')
  let d = String(date.getDate()).padStart(2, '0')
  return y + '-' + m + '-' + d
}

export function shortDate(val) {
  if (!val) return null
  let date = new Date(val)
  let y = date.getFullYear()
  let m = String(date.getMonth() + 1).padStart(2, '0')
  let d = String(date.getDate()).padStart(2, '0')
  if (y === new Date().getFullYear()) {
    return m + '-' + d
  } else {
    return y + '-' + m + '-' + d
  }
}
export function smallToBig(money) {
  if (money == undefined) return ''
  //  将数字金额转换为大写金额
  var cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'] // 汉字的数字
  var cnIntRadice = ['', '拾', '佰', '仟'] // 基本单位
  var cnIntUnits = ['', '万', '亿', '兆'] // 对应整数部分扩展单位
  var cnDecUnits = ['角', '分', '毫', '厘'] // 对应小数部分单位
  var cnInteger = '整' // 整数金额时后面跟的字符
  var cnIntLast = '元' // 整数完以后的单位
  // 最大处理的数字
  var maxNum = 999999999999999.9999
  var integerNum // 金额整数部分
  var decimalNum // 金额小数部分
  // 输出的中文金额字符串
  var chineseStr = ''
  /* var parts // 分离金额后用的数组，预定义*/
  if (money === '') {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    // 超出最大处理数字
    return '超出最大处理数字'
  }

  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  let money2 = 0
  if (money < 0) {
    money2 = money
    money = -money
  }
  // 四舍五入保留两位小数,转换为字符串
  money = Math.round(money * 100).toString()
  integerNum = money.substr(0, money.length - 2)
  const endTwoNum = money.substr(money.length - 2, money.length - 1) // 判断倒数第二位是否是0
  if (!endTwoNum || endTwoNum == 0) {
    decimalNum = '0' + money.substr(money.length - 1)
  } else {
    decimalNum = money.substr(money.length - 2)
  }

  // 获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0
    var IntLen = integerNum.length
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1)
      var p = IntLen - i - 1
      var q = p / 4
      var m = p % 4
      if (n === '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        // 归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  // 小数部分
  if (decimalNum !== '') {
    var decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      const n = decimalNum.substr(i, 1)
      if (n !== '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr === '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum === '' || /^0*$/.test(decimalNum)) {
    chineseStr += cnInteger
  }
  if (money2 < 0) {
    chineseStr = '负' + chineseStr
  }

  return chineseStr
}
