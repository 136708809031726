<template>
  <v-card-title>
    <div>{{ $attrs.title }}</div>
  </v-card-title>
</template>

<script>
export default {
  name: "myTitle",
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.v-card__title {
  font-size: 16px !important;
  font-weight: 700 !important;
}
</style>