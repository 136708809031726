import { GetMyMsgList, GetMyNewMsgListByMaxId } from '@/api/SysMsg'
const state = {
  messages: [],
  maxId: null,
}

const mutations = {
  setMessages(state, data) {
    state.messages = data
  },
  setMaxId(state, data) {
    state.maxId = data
  },
  updateMessages(state, data) {
    state.messages = [...data, ...state.messages]
  },
}
const getters = {}
const actions = {
  async getMessages({ commit }) {
    let result = await GetMyMsgList()

    if (result.length > 0) {
      commit('setMessages', result)
      commit('setMaxId', result[0].id)
    }
  },
  async updateMessages({ commit }) {
    if (!state.maxId) return
    let result = await GetMyNewMsgListByMaxId(state.maxId)
    if (result.length > 0) {
      commit('updateMessages', result)
      commit('setMaxId', result[0].id)
    }
  },
  clearMessages({ commit }) {
    commit('setMessages', [])
    commit('setMaxId', null)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
