<template>
  <div>
    <div class="flow" ref="flow"></div>
    <NodeInfo
      :option="nodeOption"
      v-if="nodeOption.value"
      @updateNodeText="updateNodeText"
    ></NodeInfo>
    <LinkInfo
      :option="linkOption"
      v-if="linkOption.value"
      @updateLinkText="updateLinkText"
    ></LinkInfo>
  </div>
</template>
<script>
import LogicFlow from "@logicflow/core";
import { Menu, DndPanel, Control } from "@logicflow/extension";
import "@logicflow/core/dist/style/index.css";
import "@logicflow/extension/lib/style/index.css";
LogicFlow.use(Menu);
LogicFlow.use(DndPanel);
LogicFlow.use(Control);

import { adapterIn, adapterOut } from "./adapter";
import registerStart from "./RegisterNode/registerStart";
import registerEnd from "./RegisterNode/registerEnd";
import registerApproval from "./RegisterNode/registerApproval";
import NodeInfo from "./dialog/NodeInfo.vue";
import LinkInfo from "./dialog/LinkInfo.vue";

export default {
  name: "CctFlow",
  components: { NodeInfo, LinkInfo },
  props: {
    flowDesign: {
      type: Object,
    },
  },
  data() {
    return {
      lf: null,
      nodeOption: { value: false, width: 800, title: "节点信息" },
      linkOption: { value: false, width: 800, title: "链接信息" },
    };
  },
  methods: {
    nodeClick(data) {
      this.nodeOption.id = data.data.id;
      this.nodeOption.value = true;
    },
    edgeClick(data) {
      this.linkOption.id = data.data.id;
      this.linkOption.value = true;
    },
    updateNodeText(id, text) {
      const nodeModel = this.lf.getNodeModelById(id);
      nodeModel.updateText(text);
    },
    updateLinkText(id, text) {
      const edgeModel = lf.getEdgeModelById(id);
      edgeModel.updateText(text);
    },
  },
  mounted() {
    const lf = new LogicFlow({
      container: this.$refs.flow,
      grid: true,
      width: 1180,
      height: 800,
      edgeTextEdit: false,
    });
    this.lf = lf;
    lf.adapterIn = adapterIn;
    lf.adapterOut = adapterOut;
    registerStart(lf);
    registerEnd(lf);
    registerApproval(lf);
    lf.extension.menu.setMenuConfig({
      nodeMenu: [
        {
          text: "删除",
          callback: (node) => {
            lf.deleteNode(node.id);
          },
        },
      ],
      edgeMenu: [
        {
          text: "删除",
          callback: (edge) => {
            lf.deleteEdge(edge.id);
          },
        },
      ],
      graphMenu: false,
    });
    lf.extension.dndPanel.setPatternItems([
      {
        type: "start",
        text: "开始",
        label: "开始节点",
        className: "circle",
      },
      {
        type: "approval",
        text: "审核",
        label: "审核节点",
        className: "rect",
      },
      {
        type: "end",
        text: "结束",
        label: "结束节点",
        className: "circle",
      },
    ]);
    lf.extension.control.addItem({
      iconClass: "mdi mdi-crosshairs",
      title: "",
      text: "定位",
      onClick: (lf, ev) => {
        lf.resetTranslate();
      },
    });
    lf.extension.control.addItem({
      iconClass: "mdi mdi-content-save-outline",
      title: "",
      text: "保存流程",
      onMouseEnter: (lf, ev) => {},
      onClick: (lf, ev) => {
        this.$emit("saveFlowDesign", lf.getGraphData());
      },
    });
    lf.on("node:click", (data) => {
      this.nodeClick(data);
    });
    lf.on("edge:click", (data) => {
      this.edgeClick(data);
    });
    lf.render(this.flowDesign);
  },
};
</script>
<style lang="scss" scoped>
.flow {
  height: 800px;
}
::v-deep .lf-control-item {
  i {
    color: #000;
    font-size: 20px;
  }
}
::v-deep .circle {
  .lf-dnd-shape {
    width: 40px;
    height: 40px;
    background-color: #fff;
    //border: "2px solid #FF6347";
    border: 2px solid black;
    border-radius: 50%;
    z-index: 110;
  }
}
::v-deep .rect {
  .lf-dnd-shape {
    width: 40px;
    height: 40px;
    background-color: #fff;
    //border: "2px solid #FF6347";
    border: 2px solid black;
    z-index: 110;
  }
}
</style>
