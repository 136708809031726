import { CircleNode, CircleNodeModel } from "@logicflow/core";

class StartNodeView extends CircleNode {
}
class StartNodeModel extends CircleNodeModel {
  initNodeData(data) {
    data.text = {
      value: data.text.value || data.text || '',
      x: data.x,
      y: data.y,
      draggable: false,
      editable: false
    }
    super.initNodeData(data)
    this.r = 35
  }
  getNodeStyle() {
    const style = super.getNodeStyle();
    style.stroke = "green";
    return style;
  }
  getConnectedTargetRules() {
    const rules = super.getConnectedTargetRules()
    const notAsTarget = {
      message: '起始节点不能作为连线的终点',
      validate: () => false
    }
    rules.push(notAsTarget)
    return rules
  }
}

export default function registerStart(lf) {
  lf.register({
    type: "start",
    view: StartNodeView,
    model: StartNodeModel
  })
}
