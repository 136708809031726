import { CircleNode, CircleNodeModel } from "@logicflow/core";

class EndNodeView extends CircleNode {
}
class EndNodeModel extends CircleNodeModel {
  initNodeData(data) {
    data.text = {
      value: data.text.value || data.text || '',
      x: data.x,
      y: data.y,
      draggable: false,
      editable: false
    }
    super.initNodeData(data)
    this.r = 35
  }
  getNodeStyle() {
    const style = super.getNodeStyle();
    style.stroke = "red";
    return style;
  }
  getConnectedSourceRules () {
    const rules = super.getConnectedSourceRules()
    const notAsTarget = {
      message: '终止节点不能作为连线的起点',
      validate: () => false
    }
    rules.push(notAsTarget)
    return rules
  }
}

export default function registerEnd(lf) {
  lf.register({
    type: "end",
    view: EndNodeView,
    model: EndNodeModel
  })
}
