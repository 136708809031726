export function adapterIn(userData) {
  let nodes = userData.flowTempNodes.map(item => {
    return {
      id: item.nodeGuid,
      text: { x: item.x, y: item.y, value: item.nodeName },
      type: nodeTypeToType(item.nodeType),
      x: item.x,
      y: item.y,
    }
  })
  let edges = userData.flowTempLinks.map(item => {
    return {
      id: item.linkGuid,
      type: item.lineType,
      sourceNodeId: item.sourceNodeId,
      targetNodeId: item.targetNodeId,
      startPoint: stringToPoint(item.startPoint),
      endPoint: stringToPoint(item.endPoint),
      pointsList: item.pointsList.split('|').map(p => stringToPoint(p))
    }
  })
  return { nodes, edges };
}
export function adapterOut(logicFlowData) {
  let flowTempNodes = logicFlowData.nodes.map(item => {
    return {
      nodeGuid: item.id,
      nodeName: item.text.value,
      nodeType: typeToNodeType(item.type),
      x: item.x,
      y: item.y,
    }
  })
  let flowTempLinks = logicFlowData.edges.map(item => {
    return {
      linkGuid: item.id,
      lineType: item.type,
      sourceNodeId: item.sourceNodeId,
      targetNodeId: item.targetNodeId,
      startPoint: `${item.startPoint.x}*${item.startPoint.y}`,
      endPoint: `${item.endPoint.x}*${item.endPoint.y}`,
      pointsList: item.pointsList.map(p => `${p.x}*${p.y}`).join('|')
    }
  })
  return { flowTempNodes, flowTempLinks };
}
function nodeTypeToType(nodeType) {
  switch (nodeType) {
    case 1:
      return 'start';
    case 2:
      return 'approval';
    case 3:
      return 'end';
    default:
      return '';
  }
}
function typeToNodeType(type) {
  switch (type) {
    case 'start':
      return 1;
    case 'approval':
      return 2;
    case 'end':
      return 3;
    default:
      return 0;
  }
}
function stringToPoint(str) {
  let arr = str.split('*')
  return { x: Number(arr[0]), y: Number(arr[1]) }
}