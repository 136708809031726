import request from '@/utility/http'

/**
 * @description 获取产品列表
 * @param {Object} { PageIndex: 1, PageSize: 2, ProductStatus: 待上架（NotOnTheShelf），已上架（OnTheShelf），已下架（RemovedFromShelf）, SearchKey: '', Area: '', WarehouseLocation: '', SupplierTypeBig: '', Category: '' }
 * @returns {Object} {mainData,sysCodeGroups}
 */
export function getProductList(queryObj) {
  if (!queryObj) return
  let obj = {}
  for (let key in queryObj) {
    if (queryObj[key]) obj[key] = queryObj[key]
  }
  let q = new URLSearchParams(Object.entries(obj)).toString()
  return request.get(`/api/PLProduct/GetProductList?${q}`)
}
/**
 * @description 获取仓储选项
 * @returns
 */
export function getWarehouseLocationList() {
  return request.get(`/api/SysCodeGroup/GetCodeList/ProductWarehouseLocation`)
}
/**
 * @description 获取空间列表
 * @returns
 */
export function getAreaList() {
  return request.get(`/api/PLProduct/GetAreaList`)
}
/**
 * @description 获取标签列表
 * @returns
 */
export function getCategoryList() {
  return request.get(`/api/PLProduct/GetCategoryList`)
}
/**
 * @description
 * @returns 获取商品编号列表
 */
export function getCodeList() {
  return request.get(`/api/PLProduct/GetCodeList`)
}
/**
 * @description 获取供应商类别的产品分类列表
 * @returns GetSupplierTypeCategoryList
 */
export function getSupplierTypeCategoryList() {
  return request.get(`/api/PLProduct/GetSupplierTypeCategoryList`)
}
/**
 * @description 修改产品分类排序
 * @param {Object} data
 * @returns GetSupplierTypeCategoryList
 */
export function updateCategoryOrder(data) {
  return request.post(`/api/PLProduct/UpdateCategoryOrder`, data)
}
/**
 * @description
 * @returns 获取供应商大类小类列表
 */
export function getSupplierTypeList() {
  return request.get(`/api/PLProduct/GetSupplierTypeList`)
}
/**
 * @description 根据小类和是否有产品获取供应商列表
 * @param {string} supplierType//供应商类型
 * @param {number} haveProduct //是否已经有产品
 * @returns
 */
export function getSupplierList(supplierType, haveProduct) {
  return request.get(`/api/PLProduct/GetSupplierList?supplierType=${supplierType}`)
}
/**
 * @description 获取我的购物车🛒
 * @returns
 */
export function getMyCart() {
  return request.get(`/api/PLProduct/GetMyShoppingCart`)
}
/**
 * @description 将sku加入到购物车🛒
 * @param {Number} skuId
 * @returns
 */
export function addToCart(skuId) {
  return request.post(`/api/PLProduct/AddToShoppingCart`, skuId, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 设置购物车🛒每件sku的数量
 * @param {Number} id sku在购物车🛒中的id
 * @param {Number} count
 * @returns
 */
export function updateCountInCart(id, count) {
  return request.post(`/api/PLProduct/UpdateCountInCart/${id}`, count, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 修改购物车🛒所选的sku
 * @param {Number} id sku在购物车🛒中的id
 * @param {Number} newSkuId 要替换的skuId
 * @returns
 */
export function updateCartSku(id, newSkuId) {
  return request.post(`/api/PLProduct/UpdateCartSku/${id}`, newSkuId, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 购物车🛒下单
 * @param {Number} pmId 项目id
 * @param {Array} arrSkuId sku在购物车🛒中的id数组
 * @returns
 */
export function createOrderInCart(pmId, arrSkuId) {
  return request.post(`/api/PLProduct/CartToOrder/${pmId}`, arrSkuId, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 获取可以选品的项目列表
 * @returns
 */
export function getMyProjects() {
  return request.get(`/api/PLProduct/GetSelectionProductProjectList`)
}
/**
 * @description
 * @returns 根据项目ID获取项目选品信息
 */
export function getProjectProductSelectionInfo(PMId) {}
/**
 * @description
 * @returns 根据项目ID获取项目订单
 */
export function getProjectOrderList(PMId) {}
/**
 * @description 获取场景列表
 * @param {String} productStatus 场景状态：未上架（NotOnTheShelf），已上架（OnTheShelf）,不传就是所有场景
 * @returns
 */
export function getSceneList(productStatus = '') {
  return request.get(`/api/PLProduct/GetSceneList${productStatus === '' ? '' : `?productStatus=${productStatus}`}`)
}
/**
 * @description 根据ID获取场景的详细信息
 * @param {Number} sceneId 场景id
 * @returns
 */
export function getScene(sceneId) {
  return request.get(`/api/PLProduct/GetScene?id=${sceneId}`)
}
/**
 * @description 新增场景
 * @param {Object} data
 * @returns
 */
export function addScene(data) {
  return request.post(`/api/PLProduct/AddScene`, data)
}
/**
 * @description 修改场景
 * @param {Object} data
 * @returns
 */
export function updateScene(data) {
  return request.post(`/api/PLProduct/UpdateScene`, data)
}
/**
 * @description 删除场景
 * @param {Number} sceneId 场景id
 * @returns
 */
export function deleteScene(sceneId) {
  return request.delete(`/api/PLProduct/DeleteScene/${sceneId}`)
}
/**
 * @description 新增场景sku
 * @param {Object} data
 * @returns
 */
export function addSceneSku(data) {
  return request.post(`/api/PLProduct/AddSceneSku`, data)
}

/**
 * @description 修改场景sku
 * @param {Object} data
 * @returns
 */
export function updateSceneSku(data) {
  return request.post(`/api/PLProduct/UpdateSceneSku`, data)
}
/**
 * @description 修改场景sku的数量
 * @param {Number} skuId 场景sku的Id
 * @param {Number} count
 * @returns
 */
export function updateSceneSkuCount(skuId, count) {
  return request.post(`/api/PLProduct/UpdateCountInSceneSku/${skuId}`, count, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 上架场景
 * @param {Number} sceneId 场景的Id
 * @returns
 */
export function shelfScene(sceneId) {
  return request.post(`/api/PLProduct/ShelfScene`, sceneId, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 下架场景
 * @param {Number} sceneId 场景的Id
 * @returns
 */
export function takedownScene(sceneId) {
  return request.post(`/api/PLProduct/TakedownScene`, sceneId, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 将场景内所有sku加入到购物车
 * @param {Number} sceneId 场景id
 * @returns
 */
export function addSceneToCart(sceneId) {
  return request.post(`/api/PLProduct/AddSceneToShoppingCart`, sceneId, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 获取场景的标签列表
 * @returns
 */
export function getSceneCategoryList() {
  return request.get(`/api/PLProduct/GetSceneCategoryList`)
}

/**
 * @description
 * @returns 获取我的项目订单的项目列表
 */
export function getProjectProductSelectionList() {}
/**
 * @description 获取订单列表
 * @param {String} listType 列表类型：DaiXiaFuKuan待下单，YiXiaFuKuan已下单，MyOrder我的订单
            ，ProjectOrder项目的订单列表，XiangMuXuanPin项目选品页面产生的订单
 * @param {Number} pmId 项目的pmId，listType为ProjectOrder和XiangMuXuanPin需要传此参数
 * @returns 根据项目id获取项目商品列表
 */
export function getOrderList(listType, pmId = '') {
  return request.get(`/api/PLProduct/GetOrderList?listType=${listType}${pmId ? '&pmId=' + pmId : ''}`)
}
/**
 * @description 获取一个项目下的已经选的产品列表
 * @param {Number} pmId 项目的pmId
 * @param {String} listType 列表类型：ALL项目下所有产品，不传此参数就默认只展示已经生成项目付款单的产品
 * @returns
 */
export function getProjectProductList(pmId, listType = '') {
  return request.get(`/api/PLProduct/GetProjectProductList?pmId=${pmId}${listType ? '&listType=' + listType : ''}`)
}

/**
 * @description
 * @returns 根据所有商品订单列表，根据传入参数区分已支付和未支付
 */
export function getProductOrderList(listType) {}
/**
 * @description 根据订单id获取订单详情
 * @returns
 */
export function getOrder(id) {
  return request.get(`/api/PLProduct/GetOrder?id=${id}`)
}
/**
 * @description 修改商品订单
 * @returns
 */
export function updateOrder(data) {
  return request.post(`/api/PLProduct/UpdateOrder`, data)
}
/**
 * @description 删除商品订单
 * @returns
 */
export function deleteOrder(id) {
  return request.delete(`/api/PLProduct/DeleteOrder/${id}`)
}
/**
 * @description 修改商品订单下的sku列表的count
 * @returns
 */
export function updateOrderSkuCount(id, count) {
  return request.post(`/api/PLProduct/UpdateCountInOrderSku/${id}`, count, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 获取库存列表
 * @returns
 */
export function getStockList(id) {
  return request.get(`/api/PLProduct/GetWarehousingEntryList`)
}
/**
 * @description 获取单sku的库存列表
 * @param {Number} skuId
 * @returns
 */
export function getSkuStockList(skuId) {
  return request.get(`/api/PLProduct/GetSkuWarehousingEntryList/${skuId}`)
}
/**
 * @description 新增库存
 * @param {Object} data
 * @returns
 */
export function addSkuStock(data) {
  return request.post(`/api/PLProduct/AddProductWarehousingEntry`, data)
}
/**
 * @description 修改库存
 * @param {Object} data
 * @returns
 */
export function updateSkuStock(data) {
  return request.post(`/api/PLProduct/UpdateProductWarehousingEntry`, data)
}
/**
 * @description 删除库存记录
 * @param {Number} id 库存记录id
 * @returns
 */
export function delSkuStock(id) {
  return request.delete(`/api/PLProduct/DeletedProductWarehousingEntry/${id}`)
}
/**
 * @description，商品状态：待上架（NotOnTheShelf），已上架（OnTheShelf），已下架（RemovedFromShelf）
 * @returns 根据状态获取sku列表
 */
export function getProductSkuList(productStatus) {
  return request.get(`/api/PLProduct/GetProductSkuList?productStatus=${productStatus}`)
}
/**
 * @description 根据id获取商品详情
 * @returns
 */
export function getProductInfo(productId) {
  return request.get(`/api/PLProduct/Get/${productId}`)
}

/**
 * @description 新增spec
 * @param {Object} data spec对象
 * @returns
 */
export function addProductSpec(data) {
  return request.post(`/api/PLProduct/AddProductSpec`, data)
}
/**
 * @description 修改spec
 * @param {Object} data spec对象
 * @returns
 */
export function updateProductSpec(data) {
  return request.post(`/api/PLProduct/UpdateProductSpec`, data)
}
/**
 * @description 删除spec
 * @param {Number} id spec对象的id
 * @returns
 */
export function deleteProductSpec(id) {
  return request.delete(`/api/PLProduct/DeletedProductSpec/${id}`)
}

/**
 * @description 新增sku
 * @param {Object} data sku对象
 * @returns
 */
export function addProductSku(data) {
  return request.post(`/api/PLProduct/AddProductSku`, data)
}
/**
 * @description 修改sku
 * @param {Object} data sku对象
 * @returns
 */
export function updateProductSku(data) {
  return request.post(`/api/PLProduct/UpdateProductSku`, data)
}
/**
 * @description 修改sku关联的产品图Id
 * @param {Number} skuId skuId
 * @param {String} imgGuid sku的图片id
 * @returns
 */
export function updateProductSkuImg(skuId, imgGuid) {
  return request.post(`/api/PLProduct/UpdateProductSkuImg/${skuId}`, imgGuid, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 删除sku
 * @param {Number} id sku对象的id
 * @returns
 */
export function deleteProductSku(id) {
  return request.delete(`/api/PLProduct/DeletedProductSku/${id}`)
}
/**
 * @description 上架sku
 * @param {Number} id sku的id
 * @returns
 */
export function shelfProductSku(id) {
  return request.post(`/api/PLProduct/ShelfProductSku`, id, { headers: { 'Content-type': 'application/json' } })
}
/**
 * @description 下架sku
 * @param {Number} id sku的id
 * @returns
 */
export function takedownProductSku(id) {
  return request.post(`/api/PLProduct/TakedownProductSku/`, id, { headers: { 'Content-type': 'application/json' } })
}

/**
 * @description 新增Product
 * @param {Object} data Product对象
 * @returns
 */
export function addProduct(data) {
  return request.post(`/api/PLProduct/Add`, data)
}
/**
 * @description 修改Product
 * @param {Object} data Product对象
 * @returns
 */
export function updateProduct(data) {
  return request.post(`/api/PLProduct/Update`, data)
}
/**
 * @description 删除Product
 * @param {Number} id Product对象的id
 * @returns
 */
export function deleteProduct(id) {
  return request.delete(`/api/PLProduct/Delete/${id}`)
}
/**
 * @description 产品图片排序
 * @param {Array} 产品图片数组
 * @returns
 */
export function sortImg(imgs) {
  return request.post(`/api/SysAttachment/AttachmentSort`, imgs)
}
/**
 * @description 获取关联的产品列表
 * @param {Number} mainProductId
 * @returns
 */
export function getRelationProductList(mainProductId) {
  return request.get(`/api/PLProduct/GetRelationProductList/${mainProductId}`)
}
/**
 * @description 添加产品关联
 * @param {Object} obj {
  "id": 0,
  "createDateTime": "2019-08-24T14:15:22Z",
  "mainProductId": 0,
  "relateProductId": 0
}
 * @returns
 */
export function addProductRelation(obj) {
  return request.post(`/api/PLProduct/AddProductRelation`, obj)
}

/**
 * @description 删除产品关联产品关联
 * @param {Object} obj {
  "id": 0,
  "createDateTime": "2019-08-24T14:15:22Z",
  "mainProductId": 0,
  "relateProductId": 0
}
 * @returns
 */
export function deletedProductRelation(obj) {
  return request.post(`/api/PLProduct/DeletedProductRelation`, obj)
}
