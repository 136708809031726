import { a } from '../../route/index'
const state = {
    // routes: [],
    addRoutes: [],
    children: [],

}


const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        // state.routes = constantRoutes.concat(routes)
    },
    DELETE_ROUTES: (state) => {
        state.addRoutes = []
        // state.routes = []
    },
    SER_CHILDREN: (state, arr) => {
        state.children = arr
    },

}


const actions = {
    async generateRoutes ({ commit }) {
        let res = await a()
        return new Promise(resolve => {
            // let accessedRoutes= filterAsyncRoutes(asyncRoutes, roles)
            commit('SET_ROUTES', res)
            resolve(res)
        })
    }
}

function hasPermission (roles, route) {
    if (route.meta && route.meta.roles) {
        return roles.some(role => route.meta.roles.includes(role))
    } else {
        return true
    }
}

export function filterAsyncRoutes (routes, roles) {
    const res = []
    routes.forEach(route => {
        const tmp = { ...route }
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles)
            }
            res.push(tmp)
        }
    })

    return res;
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}