var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"msgbox-fade"},on:{"after-leave":_vm.handleAfterLeave}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:[
      'message_wrapper',
      { success: _vm.type === 'success' },
      { warning: _vm.type === 'warning' },
      { error: _vm.type === 'error' },
      { info: _vm.type === 'info' },
    ],style:({ top: _vm.styleTop + 'px' })},[_vm._v(" "+_vm._s(_vm.message)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }