<template>
  <div class="">
    <my-dialog :option="option">
      <template slot="content">
        <div style="padding: 12px 24px !important">
          <v-row>
            <v-col cols="12">
              <v-form ref="form" cols="12">
                <v-row>
                  <v-col cols="2">
                    <div class="text-right line_44">节点名称</div>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field dense v-model="data.nodeName"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-right line_44">可选办理人</div>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      dense
                      :rules="$requiredRules"
                      v-model="data.isCanSelectUser"
                      item-text="text"
                      item-value="value"
                      :menu-props="{
                        bottom: true,
                        offsetY: true,
                      }"
                      :items="[
                        { value: true, text: '是' },
                        { value: false, text: '否' },
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-right line_44">通过模式</div>
                  </v-col>
                  <v-col cols="10">
                    <v-select
                      dense
                      :rules="$requiredRules"
                      v-model="data.nodePassType"
                      item-text="codeName"
                      item-value="codeId"
                      :menu-props="{
                        bottom: true,
                        offsetY: true,
                      }"
                      :items="flowNodePassTypeEnum"
                    ></v-select>
                  </v-col>
                  <v-col cols="2">
                    <div class="text-right line_44">节点用户SQL</div>
                  </v-col>
                  <v-col cols="10">
                    <v-textarea dense v-model="data.nodeUserSQL" auto-grow rows="2"></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </template>
      <template slot="bottom">
        <v-btn color="primary" style="margin-right: 10px" @click="save">保存</v-btn>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import * as api from '@/api/FlowTemp'

export default {
  name: 'CctFlowNodeInfo',
  props: ['option'],
  data() {
    return {
      data: {},
      flowNodePassTypeEnum: [
        { codeId: 1, codeName: '节点所有用户都需要通过才算通过' },
        { codeId: 2, codeName: '只要节点有一个用户通过就算通过' },
      ],
    }
  },
  methods: {
    async get() {
      let res = await api.GetFlowTempNodeByGuid(this.option.id)
      this.data = res.mainData
    },
    async save() {
      let res = await api.UpdateFlowTempNode(this.data)
      if (res) {
        this.$message({ type: 'success', message: '保存成功' })
      }
      this.$emit('updateNodeText', this.option.id, this.data.nodeName)
    },
  },
  async mounted() {
    await this.get()
  },
}
</script>
<style lang="scss" scoped></style>
