const getters = {
    mini: state => state.app.mini,
    drawer: state => state.app.drawer,
    // routes:state=>state.permission.routes,
    addRoutes: state => state.permission.addRoutes,
    appBarHeight: state => state.app.appBarHeight,
    children: state => state.permission.children,
    accessToken: state => state.app.accessToken,
    refreshToken: state => state.app.refreshToken,
    appBarshow: state => state.app.appBarshow,
    viewHeight: state => state.app.viewHeight,
    proportion: state => state.app.proportion,

    // mini:state=>state.app.mini,
    // mini:state=>state.app.mini,
    // mini:state=>state.app.mini,
    // mini:state=>state.app.mini,
    // mini:state=>state.app.mini
}
export default getters