<template>
  <v-card class="mycard npmargin h100">
    <v-card-title
      class="d-flex justify-content-between"
      style="padding-bottom: 6px"
    >
      <div class="d-flex align-center">
        <span> {{ this.title }}</span>
        <slot name="seach"></slot>
      </div>

      <div class="towButton">
        <div></div>
        <v-icon
          class="myhover"
          @click="$emit('Add')"
        >mdi-plus</v-icon>
      </div>
    </v-card-title>
    <v-list class="myScroll ListScroll">
      <v-list-item-group
        color="primary"
        :mandatory="selectedItem != -1"
        :value="selectedItem"
      >
        <template v-for="(item, index) in list">
          <v-list-item
            :key="index"
            @click="$emit('rowClick', item)"
          >
            <v-list-item-content>
              <slot
                name="list"
                :text="item"
              ></slot>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
    <!-- <my-list>
            <v-list-item-group  color="primary">
              <v-list-item v-for="(item, i) in list" :key="i" color="#337ab7">
                <v-list-item-content @click="$emit('GetCode',item.id)">
                  <slot name="list" :text="item"></slot>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </my-list> -->
  </v-card>
</template>

<script>
export default {
  name: "List",
  props: ["list", "title", "selectedItem"],
  data () {
    return {};
  },

  mounted () { },

  methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep .v-list-item-group {
  border: 1px solid #ccc;
}
// ::v-deep .v-list-item--link {
//   border-bottom: 1px solid #ccc;
// }
.v-card__title {
  font-size: 16px !important;
  font-weight: 700 !important;
}
.towButton {
  display: flex;
  justify-content: space-between;
  width: 80px;
}
.my-bottom {
  padding: 0 20%;
}
.my-icon {
  font-size: 24px;
  border: none;
  color: rgba(0, 0, 0, 0.54);
  padding: 0 !important;
  width: 100%;
  height: 100%;
}
.my-icon::before {
  content: "\F0374";
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
}
::v-deep .el-popover__reference-wrapper {
  display: inline-block;
  width: 34px !important;
  height: 34px !important;
}
.cursor {
  cursor: pointer;
}
.mycard .v-list-item__content {
  border-bottom: 1px solid #ccc;
}
.ListScroll {
  height: calc(100% - 52px);
}
</style>
