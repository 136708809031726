import request from "../utility/http"

/**
 * 请求到款信息列表
 * @param {Number} cpnid 付款节点的cpnid
 * @returns 返回列表数据
 */
export function BMContractinGatheringGetList(cpnid){
    return request.get("/api/BMContractinGathering/GetListByCPNId/"+cpnid)
}

/**
 * 请求到款信息选项
 * @param {传入列表id} id 
 * @returns 返回到款信息选项
 */
export function BMContractinGatheringGet(id,cpnId){
    return request.get(`/api/BMContractinGathering/Get/${id}`+(cpnId?`?cpnId=${cpnId}`:''))
}

/**
 * 新增到款信息
 * @param {传入新增的列表内容} data 
 * @returns 返回增加成功或者失败
 */
export function BMContractinGatheringPostAdd(data){
    return request.post("/api/BMContractinGathering/Add",data)
}

/**
 * 更新到款信息
 * @param {传入要更新的列表内容} data 
 * @returns 返回更新成功或者失败
 */
export function BMContractinGatheringPostUpdate(data){
    return request.post("/api/BMContractinGathering/Update",data)
}

/**
 * 删除到款信息的某个
 * @param {传入删除某个列表的id} id 
 * @returns 返回删除成功或者失败
 */
export function BMContractinGatheringDelete(id){
    return request.delete(`/api/BMContractinGathering/Delete/${id}`)
}

