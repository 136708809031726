<template>
  <!-- <v-container :width="PassToChild.width" :height="PassToChild.height"> -->
  <v-overlay
    :value="PassToChild.overlay"
    :z-index="10"
  >
    <div
      class="box"
      :style="{'width':PassToChild.width+'px','height':PassToChild.height+'px'}"
    >
      <v-row justify="space-between">
        <v-col
          cols="12"
          md="4"
        >
          <v-form ref="form">
            <v-text-field
              v-model="model"
              :counter="max"
              :rules="rules"
              label="First name"
              color="#ccc"
            ></v-text-field>
          </v-form>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-slider
            v-model="max"
            label="Max characters"
          > </v-slider>

          <v-checkbox
            dense
            v-model="allowSpaces"
            label="Allow spaces"
          ></v-checkbox>

          <v-text-field
            dense
            v-model="match"
            label="Value must match"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-btn
      color="success"
      @click="click"
    > Hide Overlay </v-btn>
  </v-overlay>
  <!-- </v-container> -->
</template>

<script>
export default {
  name: "AppOverlay",
  props: ["PassToChild"],
  data () {
    return {
      allowSpaces: false,
      match: "Foobar",
      max: 0,
      model: "Foobar",
    };
  },
  watch: {
    match: "validateField",
    max: "validateField",
    model: "validateField",
  },
  mounted () {
  },
  methods: {
    click () {
      this.$emit("update:PassToChild", {
        width: "800",
        height: "300",
        overlay: false,
      });
    },
    validateField () {
      this.$refs.form.validate();
    },
  },
  computed: {
    rules () {
      const rules = [];

      if (this.max) {
        const rule = (v) =>
          (v || "").length <= this.max ||
          `A maximum of ${this.max} characters is allowed`;

        rules.push(rule);
      }

      if (!this.allowSpaces) {
        const rule = (v) =>
          (v || "").indexOf(" ") < 0 || "No spaces are allowed";

        rules.push(rule);
      }

      if (this.match) {
        const rule = (v) => (!!v && v) === this.match || "Values do not match";

        rules.push(rule);
      }

      return rules;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  background-color: #fff;
  color: #ccc;
}
</style>