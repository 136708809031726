<template>
  <my-alert :textFlag="true" :value.sync="Treeflag" :width="'600'" :title="'选择部门'" @off="$emit('update:Treeflag', false)">
    <v-card-text>
      <tree @transmitData="setccountTo" :propgetList="sysdeptGetList" Name="deptName"></tree>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="mx-2" @click="$emit('update:Treeflag', false)">取消</v-btn>
      <v-btn class="mx-2" color="primary" @click="setaccountToDept">确定</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </my-alert>
</template>

<script>
import { sysdeptGetList } from '../../api/sysdept'
export default {
  name: 'selectDepartment',
  props: ['Treeflag', 'deptManagers'],
  data() {
    return {
      accountToDept: null,
    }
  },
  computed: {
    sysdeptGetList() {
      return sysdeptGetList
    },
  },

  methods: {
    setccountTo(item) {
      this.accountToDept = item
    },
    setaccountToDept() {
      this.$emit('setaccountToDept', this.accountToDept)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-list-item-group {
  border: 1px solid #ccc;
}
// ::v-deep .v-list-item--link {
//   border-bottom: 1px solid #ccc;
// }
</style>
