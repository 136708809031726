import request from '../utility/http'
/**
 * @description 获取我的消息列表
 * @returns
 */
export function GetMyMsgList() {
  return request.get('/api/SysMsg/GetMyMsgList')
}

/**
 * @description 获取我的消息详情
 * @returns
 */
export function GetSysMsg(id) {
  return request.get(`/api/SysMsg/Get/${id}`)
}

/**
 * @description 获取id比传入的maxId大的未读消息列表
 * @returns
 */
export function GetMyNewMsgListByMaxId(maxId) {
  return request.get(`/api/SysMsg/GetMyNewMsgListByMaxId/${maxId}`)
}
