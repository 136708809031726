import * as attachmentApi from '@/api/SysAttachmentUpload'
import global from "@/utility/globals.js"

const state = {
  drawer: null,
  mini: false,
  appBarHeight:58,
  accessToken:'',
  refreshToken:'',
  appBarshow:true,
  viewHeight:-1,
  proportion:-1,
  fileStorageType: -1, //1就是本地磁盘存储,2是阿里云OSS服务端签名直传
  ossPolicyToken: null
}

const mutations={
  ChangeNavigationDrawer(state){
    state.mini=!state.mini
  },
  ChangeNavigationDrawerShow(state,value){
    state.drawer=value
  },
  SetToken(state,data){
    state.accessToken=data.accessToken
    state.refreshToken=data.refreshToken
  },
  SetappBarshow(state,data){
    state.appBarshow=data
  },
  setViewHeight(state,vh){
    state.viewHeight=vh
  },
  setProportion(state,proportion){
    state.proportion=proportion
  },
  SetFileStorageType: (state, fileStorageType) => {
    state.fileStorageType = fileStorageType
  },
  SetOssPolicyToken: (state, ossPolicyToken) => {
    state.ossPolicyToken = ossPolicyToken
  },
}

const actions = {
  async getFileStorageType ({ commit, state }) {
    if(state.fileStorageType != -1) return state.fileStorageType

    let res = await attachmentApi.GetFileStorageType()
    commit('SetFileStorageType', res)
    return res
  },
  async getOssPolicyToken ({ commit, state }) {
    if(state.ossPolicyToken != null) {
      let expire = parseInt(state.ossPolicyToken.expire)
      let now = Date.parse(new Date()) / 1000
      if (expire - 180 > now) { // 判断当前expire是否超过了当前时间， 如果还未超出就直接返回已存在的ossPolicyToken，180s 作为缓冲。
        return state.ossPolicyToken
      }
    }

    let callbackUrl = global.apiBaseURL + global.OSSCallbackUrl
    let regex = /^https:\/\//i;
    if (regex.test(callbackUrl)) {
      callbackUrl = "http:" + callbackUrl.slice(6);
    }
    let res = await attachmentApi.GetOSSPolicyToken(callbackUrl)
    commit('SetOssPolicyToken', res)
    return res
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
