<template>
  <div class="">
    <!-- <label for="readExcel">导入</label>
    <input
      type="file"
      id="readExcel"
      @change="handleImport"
    /> -->
    <v-file-input
      dense
      @change="handleImport"
    > </v-file-input>
  </div>
</template>
<script>
// import '' from  ''
import XLSX from "xlsx";

export default {
  name: "ReadExcel",
  components: {},
  props: {},
  data () {
    return {
      data: [],
      headers: [
        {
          text: "申请日期",
          align: "start",
          value: "createDateTime",
          filter: (value) => {
            if (!this.oppde || this.oppde.length != 2) return true;
            this.oppde[1] < this.oppde[0] == true
              ? ([this.oppde[0], this.oppde[1]] = [
                this.oppde[1],
                this.oppde[0],
              ])
              : this.oppde;
            return (
              new Date(value).setHours(0, 0, 0, 0) >=
              new Date(this.oppde[0]).setHours(0, 0, 0, 0) &&
              new Date(value).setHours(0, 0, 0, 0) <=
              new Date(this.oppde[1]).setHours(0, 0, 0, 0)
            );
          },
        },
        {
          text: "单号",
          value: "formTitle",
        },
        { text: "申请人", value: "addUserName" },
        { text: "合同编号", value: "bcCode", newV: "默认" },
        { text: "合同名称", value: "bcName", newV: "默认" },
        { text: "付款单位", value: "unitName", newV: "默认" },
        {
          text: "开票金额",
          value: "invoiceMoney",
          align: "center",
          sum: true,
          newV: 0,
        },
        {
          text: "开票日期",
          value: "invoiceDate",
          filter: (value) => {
            if (!this.oppde1 || this.oppde1.length != 2) return true;
            this.oppde1[1] < this.oppde1[0] == true
              ? ([this.oppde1[0], this.oppde1[1]] = [
                this.oppde1[1],
                this.oppde1[0],
              ])
              : this.oppde1;
            return (
              new Date(value).setHours(0, 0, 0, 0) >=
              new Date(this.oppde1[0]).setHours(0, 0, 0, 0) &&
              new Date(value).setHours(0, 0, 0, 0) <=
              new Date(this.oppde1[1]).setHours(0, 0, 0, 0)
            );
          },
        },
        { text: "发票号", value: "invoiceCode", newV: 0 },
        { text: "状态", value: "status", newV: "默认" },
        { text: "打印", value: "print", newV: "默认" },
      ],
    };
  },

  computed: {},
  methods: {
    caaa (re) {
      // console.log(re);
    },
    async handleImport (file) {
      // let file = e.target.files[0];
      await import("@/utility/ImportExcel").then(async (excel) => {
        let i = await excel.readExcel(file);
        console.log(i);
      });
    },
  },
  async created () { },
  mounted () { },
};
</script>
<style lang="scss" scoped></style>
